import React, { useCallback, useMemo, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { useState, useEffect } from "react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { createUseStyles } from "react-jss";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { LicenseManager } from "ag-grid-enterprise";
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";
import FindInPageOutlinedIcon from "@mui/icons-material/FindInPageOutlined";
import JoinInnerOutlinedIcon from "@mui/icons-material/JoinInnerOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import UserContext from "../UserContext";
import { useContext } from "react";
import { Chip } from "@mui/material";
import CustomizeChip, {
  DocumentsProcessStatus,
} from "../Components/CustomizeChip";
import AccerGreenCellStyle from "../Components/CustomizeChip";
import DocumentsStatus from "../Components/CustomizeChip";
import TextField from "@mui/material/TextField";

LicenseManager.setLicenseKey(
  "For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-30_June_2022_[v2]_MTY1NjU0MzYwMDAwMA==05007f3f89f0719199ee98bb5bd2edd5"
);

const useStyles = createUseStyles({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 15px",
  },

  pageTitle: {
    padding: "10px 0px 5px 3px",
    marginBottom: "5px",
  },

  pageDescription: {
    color: "#B3B2B3",
  },

  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flex: 0.22,
  },

  headerButton: {
    padding: "0px 20px 0px 20px",
  },
  headerButtonLast: {
    paddingRight: "20px",
    paddingLeft: "10px",
  },

  maxDetailContainer: {
    height: "88vh !important",
  },

  titleContainer: {
    display: "flex",
    alignItems: "center",
  },

  titleIcon: {},
  descriptionContainer: {
    paddingLeft: "5px",
  },
});

const Documents = (props) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const isLoading = useContext(UserContext);
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [searchText, setSearchText] = useState("");

  const [style, setStyle] = useState({
    height: "100%",
    width: "100%",
  });

  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const cellClickedListener = useCallback((e) => {
    console.log("cellClicked", e);
  });

  function MyRenderer(params) {
    return <DocumentScannerOutlinedIcon />;
  }

  const getFilterData = async (tableName, columnName) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${user.user.signInUserSession.accessToken.jwtToken}`
    );
    const body = { tableName: tableName, column: columnName };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
      redirect: "follow",
    };
    let dataToFilter = await fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/ssr/filter`,
      requestOptions
    );

    let dataToFilterJson = await dataToFilter.json();
    console.log("data :", dataToFilterJson);
    return dataToFilterJson;
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "id",
      headerName: "ID",
      valueParser: (params) => Number(params.newValue),
      comparator: (valueA, valueB) => {
        const valueAint = parseInt(valueA);
        const valueBint = parseInt(valueB);
        if (valueAint === valueBint) return 0;
        else if (valueAint > valueBint) return 1;
        return -1;
      },
      filter: "agNumberColumnFilter",
    },
    {
      field: "riskguard",
      headerName: "Risk",
      filter: "agTextColumnFilter",
    },
    {
      field: "s3_id",
      headerName: "ID",
      valueParser: (params) => Number(params.newValue),
      comparator: (valueA, valueB) => {
        const valueAint = parseInt(valueA);
        const valueBint = parseInt(valueB);
        if (valueAint === valueBint) return 0;
        else if (valueAint > valueBint) return 1;
        return -1;
      },
      filter: "agNumberColumnFilter",
    },
    { field: "filename", headerName: "Filename", filter: "agTextColumnFilter" },
    {
      field: "document_type",
      headerName: "Document Type",
      filter: "agTextColumnFilter",
    },
    { field: "document_template", hide: true, filter: "agTextColumnFilter" },
    {
      field: "process_status",
      headerName: "Status",
      cellRenderer: DocumentsProcessStatus,
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params) => {
          let jsonData = await getFilterData("documents", "process_status");
          console.log("final data", jsonData);
          params.success(jsonData);
        },
      },
    },
    {
      field: "channel_type",
      headerName: "Channel Type",
      hide: true,
      filter: "agTextColumnFilter",
    },
    { field: "channel", filter: "agTextColumnFilter" },
    {
      field: "classification_status",
      headerName: "Classification Status",
      hide: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "classification_type",
      headerName: "Classification Type",
      hide: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "classification_rule",
      headerName: "Classification Rule",
      hide: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "extraction_status",
      headerName: "Extraction Status",
      hide: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "mapping_status",
      headerName: "Mapping Status",
      hide: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "mapping_type",
      headerName: "Mapping Type",
      hide: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "mapping_rule",
      headerName: "Mapping Rule",
      hide: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "document_date",
      headerName: "Date",
      filter: "agDateColumnFilter",
    },
    // { field: "extension", hide: true },
    {
      field: "filesize",
      headerName: "File Size",
      hide: true,
      filter: "agNumberColumnFilter",
    },
    {
      field: "action",
      headerName: "Email Filter",
      hide: true,
      filter: "agTextColumnFilter",
    },
    // { field: "comment" },
    // { field: "textract" },
    // { field: "email_id", hide:true },
    // { field: "object_type", headerName: "Object Type" },
    // { field: "object", headerName: "Object" },
    // { field: "leg" },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      enableValue: true,
      enableRowGroup: true,
      enablePivot: true,
      filter: true,
      sortable: true,
      resizable: true,
      enableRowGroup: false,
      flex: 1,
      filterParams: {
        suppressAndOrCondition: true,
      },
    };
  }, []);

  // CLIENT SIDE RENDERING
  /*const onGridReady = (params) => {
    setGridApi(params.api);

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${user.user.signInUserSession.accessToken.jwtToken}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/documents`,
      requestOptions
    )
      .then((resp) => resp.json())
      .then((data) => {
        setRowData(data);
      })
      .catch((error) => console.log("error", error));
  };*/

  //SERVER SIDE RENDERING
  const serverSideRendering = {
    getRows(params) {
      // called by the grid when more rows are required
      //getRows: params => {

      console.log(
        "Test of serverside rendering :",
        JSON.stringify(params.request, null, 1)
      );

      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${user.user.signInUserSession.accessToken.jwtToken}`
      );

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(params.request),
        redirect: "follow",
      };

      //TODO
      fetch(
        `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/ssr/documents`,
        requestOptions
      )
        //.then((resp) => console.log(resp))
        .then((resp) => resp.json())
        .then((resp) => {
          console.log("last", resp);
          //params.successCallback(resp,resp.length)
          params.successCallback(resp.rows, resp.lastRow);
          //setRowData(resp)
        })
        .catch((error) => {
          console.log("error", error);
          params.fail();
        });
    },
  };

  const serverSideDatasource = serverSideRendering;

  const showPivotModeSection = useCallback(() => {
    var columnToolPanel = gridRef.current.api.getToolPanelInstance("columns");
    columnToolPanel.setPivotModeSectionVisible(true);
  }, []);

  const showRowGroupsSection = useCallback(() => {
    var columnToolPanel = gridRef.current.api.getToolPanelInstance("columns");
    columnToolPanel.setRowGroupsSectionVisible(true);
  }, []);

  const showValuesSection = useCallback(() => {
    var columnToolPanel = gridRef.current.api.getToolPanelInstance("columns");
    columnToolPanel.setValuesSectionVisible(true);
  }, []);

  const showPivotSection = useCallback(() => {
    var columnToolPanel = gridRef.current.api.getToolPanelInstance("columns");
    columnToolPanel.setPivotSectionVisible(true);
  }, []);

  const setWidthAndHeight = (width, height) => {
    setStyle({
      width,
      height,
    });
  };

  const deselect = useCallback((e) => {
    gridRef.current.api.deselectAll();
  });

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();

    const id = selectedRows.length === 1 ? selectedRows[0].id : "";

    navigate(`/documents/${id}`);
  }, []);
  // const handleSearch = useCallback((searchText) => {
  //   console.log(searchText);
  //   // Perform search logic here
  // }, []);
  // const memoizedHandleSearch = useMemo(() => handleSearch, []);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          maxWidth: 225,
          width: 225,
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
    };
  }, []);

  return (
    <>
      <div className={styles.headerContainer}>
        <div>
          <div className={styles.titleContainer}>
            <div className={styles.titleIcon}>
              <ArticleOutlinedIcon
                fontSize="large"
                sx={{
                  color: "#83b81a",
                }}
              />
            </div>
            <h1 className={styles.pageTitle}>Documents</h1>
          </div>
          <div>
            <div className={styles.descriptionContainer}>
              <p className={styles.pageDescription}>
                All documents. Use filters to find a specific document.
              </p>
            </div>
          </div>
        </div>

        <div className={styles.buttonsContainer}>
          {/* <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search Documents"
              inputProps={{ "aria-label": "Search Documents" }}
              onChange={(event) => setSearchText(event.target.value)}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon onClick={() => memoizedHandleSearch(searchText)} />
            </IconButton>
          </Paper> */}
          {/* <Button
            size="small"
            variant="contained"
            style={{ backgroundColor: "#F8F8F8", color: "black" }}
            onClick={deselect}
          >
            Deselect
          </Button> */}
        </div>
      </div>

      <div style={containerStyle}>
        <div className="ag-theme-alpine" style={gridStyle}>
          <div className={styles.maxDetailContainer}>
            <AgGridReact
              pagination={true}
              paginationPageSize={50}
              ref={gridRef}
              onCellClicked={cellClickedListener}
              onRowDoubleClicked={onSelectionChanged}
              //rowData={rowData} //CLIENT SIDE RENDERING
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows={true}
              rowSelection="multiple"
              //onGridReady={onGridReady} CLIENT SIdE RENDERiNG
              enableRangeSelection={true}
              cacheBlockSize={50}
              sideBar={sideBar}
              enableSorting={true}
              multiSortKey="ctrl"
              rowModelType="serverSide" //Need for SERVER SIDE REDERING
              serverSideStoreType="partial"
              serverSideDatasource={serverSideDatasource}
              serverSideSortOnServer={true}
              serverSideFilterOnServer={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Documents;
