import React, { useCallback, useMemo, useRef, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import { useState } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { createUseStyles } from "react-jss";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import UserContext from "../UserContext";
import { useContext } from "react";
import { RecordStatus } from "../Components/CustomizeChip";
import { DocumentScannerOutlined } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
// import { useEffect } from "react";
const useStyles = createUseStyles({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 15px",
  },

  pageTitle: {
    padding: "15px 0px 0px 10px",
    fontSize: "32px",
  },

  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flex: 0.22,
  },

  headerButton: {
    padding: "0px 20px 0px 20px",
  },
  headerButtonLast: {
    paddingRight: "20px",
    paddingLeft: "10px",
  },
  maxDetailContainer: {
    height: "88vh !important",
    // width:"100vw !important"
  },

  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
});

const SearchResults = (props) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const isLoading = useContext(UserContext);
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [isReqLoading, setIsReqLoading] = useState(true);
  const [userToken, setUserToken] = useState();
  const [searchData, setSearchData] = useState();
  const [searchDataUpdated, setSearchDataUpdated] = useState(false);

  const [style, setStyle] = useState({
    height: "100%",
    width: "100%",
  });
  const cellClickedListener = useCallback((e) => {
    console.log("cellClicked", e);
  });
  const gridRef = useRef();

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();

    const id = selectedRows.length === 1 ? selectedRows[0].id : "";

    navigate(`/document/${id}`);
  }, []);

  const location = useLocation();
  const searchText = new URLSearchParams(location.search).get("searchText");

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  myHeaders.append("Authorization", `Bearer ${userToken}`);
  var urlencoded = new URLSearchParams();
  urlencoded.append("search", searchText);
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  // const fetchResultsData = async () => {
  //   try {
  //     const res = await fetch(
  //       `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/ssr/document/view/open`,
  //       requestOptions
  //     );
  //     const data = await res.json();
  //     setSearchData(JSON.parse(data));
  //     setSearchDataUpdated(true);
  //     // setInvoiceSelectionList(invoiceData.map((item) => item.id));
  //     console.log(searchData);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  // useEffect(() => {
  //   fetchResultsData();
  //   setSearchDataUpdated(false);
  //   // setRowData(searchData);

  // }, [!searchData || searchText]);
  const onGridReady = (params) => {
    setGridApi(params.api);

    fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/ssr/document/view/open`,
      requestOptions
    )
      .then((resp) => resp.json())
      .then((data) => {
        // setRowData(data);
        try {
          const obj = JSON.parse(data);
          setRowData(obj._embedded.collection);
          console.log(rowData);
          console.log(obj);
        } catch (error) {
          console.error("rowData is not a valid JSON string:", error);
        }
      });
  };
  // useEffect(() => {
  //   window.location.reload();
  // }, [searchText]);
  useEffect(() => {
    if (!user.isLoading) {
      setUserToken(user.user.signInUserSession.accessToken.jwtToken);
    }
  }, [user.isLoading]);

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "name",
      headerName: `Name`,
      filter: "agSetColumnFilter",
    },
    {
      field: "type",
      headerName: "Type",
      filter: "agSetColumnFilter",
    },
    {
      field: "created_by.email",
      headerName: "Created By",
      filter: "agSetColumnFilter",
    },
  ]);
  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      filter: true,
      flex: 1,
    };
  }, []);
  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          maxWidth: 225,
          width: 225,
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
    };
  }, []);

  console.log(searchText);
  console.log(rowData);

  return (
    <div>
      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          <DocumentScannerOutlined
            fontSize="large"
            sx={{
              color: "#83b81a",
            }}
          />
          <p className={styles.pageTitle}>Search results</p>
        </div>
      </div>

      {/* {searchData ? ( */}
      <div style={{ width: "100%" }}>
        <div style={{ width: "100%" }}>
          <div className="ag-theme-alpine" style={{ height: 500 }}>
            <div className={styles.maxDetailContainer}>
              <AgGridReact
                ref={gridRef}
                onCellClicked={cellClickedListener}
                onRowDoubleClicked={onSelectionChanged}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                rowSelection="multiple"
                onGridReady={onGridReady}
                enableRangeSelection={true}
                pagination={true}
                paginationPageSize={50}
              />
            </div>
          </div>
        </div>
      </div>
      {/* ) : (
        <div className="invoices">
          <CircularProgress style={{ color: "gray" }} size={70} />
        </div>
      )} */}
    </div>
  );
};

export default SearchResults;
