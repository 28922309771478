import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import UserContext from "../UserContext";
import { useContext } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { createUseStyles } from "react-jss";
import { LicenseManager } from "ag-grid-enterprise";

import { CircularProgress } from "@mui/material";

LicenseManager.setLicenseKey(
  "For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-30_June_2022_[v2]_MTY1NjU0MzYwMDAwMA==05007f3f89f0719199ee98bb5bd2edd5"
);

const useStyles = createUseStyles({
  titleContainer: {
    display: "flex",

    alignItems: "center",
    marginTop: "15px",
    marginBottom: "15px",
  },

  pageTitle: {
    padding: "0px 0px 0px 10px",
    fontSize: "32px",
    marginBottom: 0,
  },

  pageSubTitle: {
    padding: "0px 0px 10px 20px",
    fontSize: "28px",
  },

  maxDetailContainer: {
    height: "88vh !important",
  },

  descriptionContainer: {
    paddingLeft: "5px",
  },

  subHeaderContainer: {
    paddingLeft: "13px",
  },
  pageDescription: {
    color: "#B3B2B3",
  },
});

const DocumentTypesAndTemplates = () => {
  const styles = useStyles();
  const gridRef = useRef();
  const user = useContext(UserContext);
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState();
  const [userToken, setUserToken] = useState();

  const [isLoading, setLoading] = useState(true);
  const [columnDefs, setColumnDefs] = useState([
    { field: "id", headerName: "ID", hide: true },
    {
      field: "name",
      headerName: "Document Type",
      cellRenderer: "agGroupCellRenderer",
    },
    { field: "record_type", headerName: "Record Type" },
  ]);
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
    };
  }, []);

  useEffect(() => {
    if (!user.isLoading) {
      setUserToken(user.user.signInUserSession.accessToken.jwtToken);
    }
  }, [user.isLoading]);
  const onGridReady = useCallback((params) => {
    fetch(
      `https://fsyajg0qfh.execute-api.eu-west-3.amazonaws.com/config/DocumentTypes`,
      {
        method: "GET",
        headers: {
          ticket: `Bearer ${userToken}`,
        },
      }
    )
      .then((resp) => resp.json())
      .then((data) => {
        setRowData(Object.values(data));

        setLoading(false);
      });
  }, []);

  const detailCellRendererParams = useMemo(() => {
    return {
      detailGridOptions: {
        columnDefs: [
          { field: "id", headerName: "ID", hide: true },
          { field: "name", headerName: "Document Template" },
          { field: "channel", headerName: "Channel" },
        ],
        defaultColDef: {
          flex: 1,
        },
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.document_templates);
      },
    };
  }, []);
  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          maxWidth: 225,
          width: 225,
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
    };
  }, []);

  if (isLoading) {
    <div className="invoices">
      <CircularProgress style={{ color: "gray" }} size={70} />
    </div>;
  }

  return (
    <div>
      <div className={styles.subHeaderContainer}>
        <div className={styles.titleContainer}>
          <div className={styles.titleIcon}>
            {/* <ShoppingCartOutlinedIcon
              fontSize="large"
              sx={{
                color: "#83b81a",
              }}
            /> */}
          </div>

          <p className={styles.pageTitle}>Document Types and Templates</p>
        </div>
        <div>
          <div className={styles.descriptionContainer}>
            {/* <p className={styles.pageDescription}>
              Orders included in this invoice
            </p> */}
          </div>
        </div>
      </div>

      <div style={containerStyle}>
        <div style={gridStyle} className="ag-theme-alpine">
          <div className={styles.maxDetailContainer}>
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              masterDetail={true}
              detailCellRendererParams={detailCellRendererParams}
              onGridReady={onGridReady}
              enableRangeSelection={true}
              pagination={true}
              paginationPageSize={50}
              sideBar={sideBar}
            ></AgGridReact>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentTypesAndTemplates;
