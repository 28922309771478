import React, { useCallback, useMemo, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { useState, useEffect } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { createUseStyles } from "react-jss";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import UserContext from "../UserContext";
import { useContext } from "react";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import IconButton from '@mui/material/IconButton';


const useStyles = createUseStyles({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 15px",
  },

  pageTitle: {
    padding: "15px 0px 0px 10px",
    fontSize: "32px",
  },

  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flex: 0.22,
  },

  headerButton: {
    padding: "0px 20px 0px 20px",
  },
  headerButtonLast: {
    paddingRight: "20px",
    paddingLeft: "10px",
  },
  maxDetailContainer: {
    height: "88vh !important",
    // width:"100vw !important"
  },

  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
});

const ClassificationRules = (props) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const isLoading = useContext(UserContext);
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);

  const [style, setStyle] = useState({
    height: "100%",
    width: "100%",
  });
  const cellClickedListener = useCallback((e) => {
    console.log("cellClicked", e);
  });
  const gridRef = useRef();

  const [columnDefs, setColumnDefs] = useState([
    { field: "id", headerName: "ID", hide: true, editable: false},
    { field: "rule_type", headerName: "Rule Type" },
    { field: "channel", headerName: "Channel", hide:true},
    { field: "channel_type", headerName: "Channel Type" },
    { field: "classification_type", headerName: "Method" },
    { field: "source", headerName: "Source" },
    { field: "input", headerName: "Field" },
    { field: "operator", headerName: "Operator" },
    { field: "if", headerName: "Condition" },
    { field: "document_type", headerName: "Document Type" },
    { field: "document_template", headerName: "Document Template" },
  ]);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      editable: true
    }),
    []
  );

  useEffect(() => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
    }
  }, [rowData]);
  const onGridReady = (params) => {
    setGridApi(params.api);

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${user.user.signInUserSession.accessToken.jwtToken}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/rules/classification`,
      requestOptions
    )
      .then((resp) => resp.json())
      .then((data) => {
        setRowData(data);
      })
      .catch((error) => console.log("error", error));
  };

  const deselect = useCallback((e) => {
    gridRef.current.api.deselectAll();
  });

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();

    const id = selectedRows.length === 1 ? selectedRows[0].invoice_number : "";

    // navigate(`/item/invoice/${id}`);
  }, []);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          maxWidth: 225,
          width: 225,
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
    };
  }, []);

  const updateClassification = (newValue) => {
    const body = {data: newValue.data}
    console.log(body)
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${user.user.signInUserSession.accessToken.jwtToken}`
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body:JSON.stringify(body),
    };

    fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/rules/classification`,
      requestOptions
    )
      .then((resp) => resp.json())
      .then(gridRef.current.api.refreshCells({force:true}))
      .catch((error)=>{
        console.log("error", error);
      })
    
  }

  const onAddRow = () => {
    gridRef.current.api.applyTransaction({ add: [{}]})
  }

  const onRemoveSelectedRow = () =>{
    var selectedRowData = gridRef.current.api.getSelectedRows()
    selectedRowData.forEach(element => {
        let rowToRemove = {'data':{'id':element.id}}
        console.log(rowToRemove)
        updateClassification(rowToRemove) 
    });
    gridRef.current.api.applyTransaction({remove: selectedRowData}) // TODO IF ANY ERROR OCCURED NOT APPLY IT
  }

  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          {/* <PaidOutlinedIcon
            fontSize="large"
            sx={{
              color: "#83b81a",
            }}
          /> */}
          <p className={styles.pageTitle}>Classification Rules</p>
        </div>
        <div className={styles.buttonsContainer}>
          <IconButton onClick={onAddRow} sx={{color: '#83b81a'}} aria-label="Add new availability rule"> 
            <AddCircleOutlineIcon/>
          </IconButton>
          <IconButton onClick={onRemoveSelectedRow} sx={{color: '#83b81a'}} aria-label="Remove selected availability rule"> 
            <RemoveCircleOutlineIcon/>
          </IconButton>
          {/* <Button
            size="small"
            variant="contained"
            style={{ backgroundColor: "#F8F8F8", color: "black" }}
            onClick={deselect}
          >
            Deselect
          </Button> */}
          {/* <Button
            size="small"
            variant="contained"
            style={{ backgroundColor: "#F8F8F8", color: "black" }}
          >
            button2
          </Button>
          <Button
            size="small"
            variant="contained"
            style={{ backgroundColor: "#F8F8F8", color: "black" }}
          >
            button3
          </Button> */}
        </div>
      </div>

      <div style={{ width: "100%" }}>
        <div className="ag-theme-alpine" style={{ height: 500 }}>
          <div className={styles.maxDetailContainer}>
            <AgGridReact
              ref={gridRef}
              onCellClicked={cellClickedListener}
              onRowDoubleClicked={onSelectionChanged}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows={true}
              rowSelection="multiple"
              onGridReady={onGridReady}
              enableRangeSelection={true}
              pagination={true}
              paginationPageSize={50}
              sideBar={sideBar}
              onRowValueChanged={updateClassification}
              editType={'fullRow'}
              enableCellChangeFlash={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ClassificationRules;