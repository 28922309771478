import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { render } from "react-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useParams } from "react-router-dom";
import { createUseStyles } from "react-jss";
import { LicenseManager } from "ag-grid-enterprise";
import OrderDocumentsComponent from "../Components/OrderDocumentsComponent";
import cc from "classcat";
import { CircularProgress } from "@mui/material";
import SuborderDocumentsComponent from "../Components/SuborderDocumentsComponent";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import UserContext from "../UserContext";
import { useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { textAlign } from "@mui/system";
import { RecordStatus } from "../Components/CustomizeChip";

LicenseManager.setLicenseKey(
  "For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-30_June_2022_[v2]_MTY1NjU0MzYwMDAwMA==05007f3f89f0719199ee98bb5bd2edd5"
);

const useStyles = createUseStyles({
  titleContainer: {
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
    marginTop: "15px",
    marginBottom: "15px",
  },

  pageTitle: {
    padding: "0px 0px 0px 10px",
    fontSize: "32px",
    marginBottom: 0,
  },

  pageSubTitle: {
    padding: "0px 0px 10px 20px",
    fontSize: "28px",
    // color: "#B3B2B3",
  },

  maxDetailContainer: {
    height: "60vh !important",
  },

  documentsTableContainer: {
    height: "40vh !important",
    marginTop: "0px",
  },
  descriptionContainer: {
    // flexDirection: "column",
    paddingLeft: "5px",
    lineHeight: "10px",
    color: "#B3B2B3",
  },

  subHeaderContainer: {
    paddingLeft: "13px",
  },
  pageDescription: {
    // fontSize: "16px",
    color: "#B3B2B3",
  },
  statusPosition:{
    position:"relative",
    paddingLeft: "15px",
    top: "5px",
    paddingRight: "5px"
  },
});

const Suborder = () => {
  let { suborderId } = useParams();
  const styles = useStyles();
  const gridRef = useRef();
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const isReqLoading = useContext(UserContext);
  const [userToken, setUserToken] = useState();
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState();
  const [subOrderData, setSubOrderData] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [columnDefs, setColumnDefs] = useState([
    // group cell renderer needed for expand / collapse icons
    {
      field: "line_number",
      cellRenderer: "agGroupCellRenderer",
      headerName: "Line Number",
    },
    { field: "status", headerName: "Status", cellRenderer: RecordStatus},
    // { field: "calls" },
    // { field: "minutes", valueFormatter: "x.toLocaleString() + 'm'" },
  ]);
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
    };
  }, []);

  var requestOptions = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  };
  useEffect(() => {
    if (!user.isLoading) {
      setUserToken(user.user.signInUserSession.accessToken.jwtToken);
    }
  }, [user.isLoading]);

  const cellClickedListener = useCallback((e) => {
    console.log("cellClicked", e);
  });

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    console.log("selectedRows", selectedRows)
    const id = selectedRows.length === 1 ? selectedRows[0].invoice_number : '';
    navigate(`/item/invoice/${id}`);
  }, []);

  async function fetchSuborderData() {
    const suborderDataRequest = await axios.get(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/item/suborder/${suborderId}`,
      requestOptions
    );

    setSubOrderData(suborderDataRequest.data[0]);
    setRowData(suborderDataRequest.data[0].lines);
    console.log(suborderDataRequest.data[0].lines);
    setLoading(false);
  }

  const onGridReady = useCallback(
    (params) => {
      if (userToken) {
        // fetch(
        //   `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/item/suborder/${suborderId}`,
        //   {
        //     method: "GET",
        //     headers: { Authorization: `Bearer ${userToken}` },
        //   }
        // )
        //   .then((resp) => resp.json())
        //   .then((data) => {
        //     setSubOrderData(data[0]);
        //     setRowData(data[0].lines);
        //     console.log(data[0].lines);
        //     setLoading(false);
        //   });
        fetchSuborderData();
      }
    },
    [userToken]
  );

  const detailCellRendererParams = useMemo(() => {
    return {
      detailGridOptions: {
        ref: gridRef,
        columnDefs: [
          { field: "sublineorderid", headerName: "Subline Number" },
          { field: "status", headerName: "Status", cellRenderer: RecordStatus},
          { field: "invoice_number", headerName: "Invoice Number" },
          { field: "shipment" },
          { field: "forwarder", headerName: "LSP" },

          // { field: "status" },

          // { field: "number", minWidth: 150 },
          // { field: "duration", valueFormatter: "x.toLocaleString() + 's'" },
          // { field: "switchCode", minWidth: 150 },
        ],
        defaultColDef: {
          flex: 1,
        },
        rowSelection:"multiple",
        onCellClicked: cellClickedListener,
        onRowDoubleClicked: onSelectionChanged,
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.sub_lines);
        // console.log(params.data.lines[0].sub_lines);
      },
    };
  }, []);

  console.log(subOrderData);

  // const onFirstDataRendered = useCallback((params) => {
  //   // arbitrarily expand a row for presentational purposes
  //   setTimeout(function () {
  //     gridRef.current.api.getDisplayedRowAtIndex(1).setExpanded(true);
  //   }, 0);

  // }, []);

  if (isLoading) {
    <div className="invoices">
      <CircularProgress style={{ color: "gray" }} size={70} />
    </div>;
  }

  return (
    <div>
      {/* <div className={styles.titleContainer}>
        <p className={styles.pageTitle}>
          {subOrderData.type} - {subOrderData.sub_order_number} -{" "}
          {subOrderData.entity}
        </p>
      </div> */}

      <div className={styles.subHeaderContainer}>
        <div className={styles.titleContainer}>
          <div className={styles.titleIcon}>
            <ShoppingCartOutlinedIcon
              fontSize="large"
              sx={{
                color: "#83b81a",
              }}
            />
          </div>

          <p className={styles.pageTitle}>
            {subOrderData.type} - {subOrderData.sub_order_number} -
            {subOrderData.entity}
          </p>
          <p className={styles.statusPosition}><RecordStatus value={subOrderData.status}/></p>
        </div>
        <div className={styles.descriptionContainer}>
          <p>Invoice Address: {subOrderData.invoice_address}</p>
          <p>Delivery Address: {subOrderData.delivery_address}</p>
          <p>Delivery Terms: {subOrderData.delivert_term}</p>
        </div>
        <div>
          <div className={styles.descriptionContainer}>
            {/* <p className={styles.pageDescription}>
              Orders included in this invoice
            </p> */}
          </div>
        </div>
      </div>

      <div style={containerStyle}>
        <div style={gridStyle} className="ag-theme-alpine">
          <div className={styles.maxDetailContainer}>
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              masterDetail={true}
              detailCellRendererParams={detailCellRendererParams}
              onGridReady={onGridReady}
            ></AgGridReact>
          </div>
          <div className={styles.documentsTableContainer}>
            <div>
              <div className={styles.subHeaderContainer}>
                <div className={styles.titleContainer}>
                  <div className={styles.titleIcon}>
                    <ArticleOutlinedIcon
                      fontSize="large"
                      sx={{
                        color: "#83b81a",
                      }}
                    />
                  </div>
                  <h1 className={styles.pageTitle}>Documents</h1>
                </div>

                <div>
                  <div className={styles.descriptionContainer}>
                    <p className={styles.pageDescription}>
                      Documents Required and Available for this order, including
                      invoicing and shipment related documents.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <SuborderDocumentsComponent suborderId={suborderId} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Suborder;
