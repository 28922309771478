import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import "./BravaViewer.css"

const BravaViewer = (id) => {
  const apiName = useSelector((state) => state.app.apiName)

  useEffect(() => {
    if (apiName) {
      const bravaApi = window[apiName]
      bravaApi.setHttpHeaders({ Authorization: "Bearer " + window.accessToken })
      bravaApi.addPublication(window.publication, true)
      bravaApi.render(id)
    }
  }, [id, apiName])

  return <div className="viewer-div" id={id} />
}

export default BravaViewer