import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  // iframeStyle: {
  //   height: "100vh",
  //   width: "100%",
  // },
});

const Home = () => {
  const styles = useStyles();

  return (
    <div>
      <iframe
        style={{ height: "100vh", width: "-webkit-fill-available" }}
        width="960"
        height="720"
        src="https://eu-west-3.quicksight.aws.amazon.com/sn/dashboards/39916656-f101-484d-a288-abe95e1d0d17/views/17502270-1aa2-471a-a15d-07d74cf8afb6"
      ></iframe>
    </div>
  );
};

export default Home;
