import { Chip, makeStyles } from '@mui/material'
import { createTheme } from '@mui/system';
import React from 'react'


const themeOfChip = createTheme({
    palette: {
        acerGreen: {
            main : '#83b81a'
        }
    }
})

const DocumentStatus = (params) => {
  const value = params.value;
  if(value === 'OK'){
    return (
        <Chip label={params.value} size="small" sx={{backgroundColor: '#83b81a', color: 'white', textAlign: 'center', paddingRight:'10px',  minWidth:'120px'}}/>
      )
  }else if(value === 'Missing'){
    return (
        <Chip label={params.value} size="small" color="warning"  sx={{paddingRight:'10px', minWidth:'120px'}}/>
      )
  }else if(value === 'N/A'){
    return (
        <Chip label={params.value} size="small" color="warning"  sx={{paddingRight:'10px', minWidth:'120px'}}/>
      )
  }else{
    return (
      <Chip label={params.value} size="small" sx={{paddingRight:'10px', minWidth:'120px'}}/>
    )
  }
}

const RecordStatus = (params) => {
    const value = params.value;
    if(value === 'OK'){
      return (
          <Chip label={params.value} size="small" sx={{backgroundColor: '#83b81a', color: 'white', textAlign: 'center', paddingRight:'10px',  minWidth:'120px'}}/>
        )
    }else if(value === 'Open' || value === 'Partial OK'){
      return (
          <Chip label={params.value} size="small" color="warning" sx={{paddingRight:'10px', minWidth:'120px'}}/>
        )
    }
    return (
      <Chip label={params.value} size="small" sx={{paddingRight:'10px', minWidth:'120px'}}/>
    )
  }

const DocumentsProcessStatus = (params) => {
    const value = params.value;
    
    switch(value){
        case 'Completed':
            return (
                <Chip label={params.value} size="small" sx={{backgroundColor: '#83b81a', color: '#FFFFFF', paddingRight:'10px',  minWidth:'120px'}}/>
              )
        case 'Classify':
            return (
                <Chip label={params.value} size="small" color="error" sx={{paddingRight:'10px', minWidth:'120px'}}/>
              )
        case 'Extract':
            return (
                <Chip label={params.value} size="small" color="error" sx={{paddingRight:'10px', minWidth:'120px'}}/>
              )
        case 'Match':
            return (
                <Chip label={params.value} size="small" color="error" sx={{paddingRight:'10px', minWidth:'120px'}}/>
              )
        default :
            return(
                <Chip label={params.value} size="small"  sx={{paddingRight:'10px', minWidth:'120px'}}/>
            )
    }
    
   
}
export {DocumentStatus, DocumentsProcessStatus, RecordStatus}