import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { UserProvider } from "./UserContext";
import { useContext } from "react";
import { Provider } from "react-redux";
import configureStore from "./redux/configureStore";
import httpRequire from "./util/httpRequire";
import { setBravaApiName } from "./redux/app";
require("./publication.js");
// require("./accessToken.js")
const loaderAuthority = "https://na-1-dev.api.opentext.com";
const store = configureStore();
var OTtoken = "";
var myHeadersOT = new Headers();
myHeadersOT.append("Content-Type", "application/json");
var rawOT = JSON.stringify({
  client_id: "0Qm59pwZw9iRKMi0GNWEja0ziI3D51al",
  client_secret: "3X9hUN2bcgpOY8HQ",
  grant_type: "password",
  username: "pvos@deloitte.com",
  password: "=0}!Z1MluUHmg&N$9OAF;p41;",
});

var requestOptionsOT = {
  method: "POST",
  headers: myHeadersOT,
  body: rawOT,
  redirect: "follow",
};

fetch(
  "https://na-1-dev.api.opentext.com/tenants/2c114a18-7b9f-4daf-951b-1222018f2408/oauth2/token",
  requestOptionsOT
)
  .then((response) => response.text())
  .then((result) =>
    console.log(
      ((OTtoken = JSON.parse(result).access_token),
      (window.accessToken = OTtoken))
    )
  )
  .catch((error) => console.log("error", error));

window.accessToken = OTtoken;

store.dispatch({ type: "app/TEST_EPIC", value: "Deloitte" });
const onBravaReady = (e) => {
  console.log("Brava Ready:", e.detail);
  store.dispatch(setBravaApiName(e.detail));
};

window.addEventListener("bravaReady", onBravaReady);
httpRequire(
  `${loaderAuthority}/viewer/api/v1/viewers/brava-view-1.x/loader`,
  () => {},
  window.accessToken
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <Provider> */}
      <UserProvider>
        <App />
      </UserProvider>
    </Provider>
  </React.StrictMode>
);
