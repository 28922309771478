import React, { useCallback, useMemo, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { useState, useEffect } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { createUseStyles } from "react-jss";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import UserContext from "../UserContext";
import { useContext } from "react";
import { RecordStatus } from "../Components/CustomizeChip";

const useStyles = createUseStyles({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 15px",
  },

  pageTitle: {
    padding: "15px 0px 0px 10px",
    fontSize: "32px",
  },

  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flex: 0.22,
  },

  headerButton: {
    padding: "0px 20px 0px 20px",
  },
  headerButtonLast: {
    paddingRight: "20px",
    paddingLeft: "10px",
  },
  maxDetailContainer: {
    height: "88vh !important",
    // width:"100vw !important"
  },

  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
});

const Invoices = (props) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const isLoading = useContext(UserContext);
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [isReqLoading, setIsReqLoading] = useState(true);

  const [style, setStyle] = useState({
    height: "100%",
    width: "100%",
  });
  const cellClickedListener = useCallback((e) => {
    console.log("cellClicked", e);
  });
  const gridRef = useRef();

  const getFilterData = async (tableName, columnName) => {
    console.log("this is inside get filter data");
    // if(!user.isLoading){
    //     let userToken =
    //   }else{
    //     let userToken = ''
    //   }
    var myHeaders = new Headers();
    let fetchUserToken = () => {
      console.log(user.user.signInUserSession.accessToken.jwtToken);
    };
    fetchUserToken();
    myHeaders.append(
      "Authorization",
      // `Bearer ${}`
      `Bearer eyJraWQiOiJpOWg0TURKcGZrT2U3akU0SUhtQUJGU0thVXRIejlEcTF4Qk4xSVRzdlE0PSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI2ZWppZWpxbmdkcXQwZW5jYW5pcG9wY2VtMCIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiQWNlci1EZXYtVXNlci1SRVNULUFQSUBSZXNvdXJjZS1TZXJ2ZXJcL0FjZXItRGV2LVVzZXItUkVTVC1BUEkuRnVsbEFjY2Vzc0BTY29wZSIsImF1dGhfdGltZSI6MTY2NzU2NTU1OCwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LXdlc3QtMi5hbWF6b25hd3MuY29tXC9ldS13ZXN0LTJfdlhTSHJ2VHU4IiwiZXhwIjoxNjY3NTY5MTU4LCJpYXQiOjE2Njc1NjU1NTgsInZlcnNpb24iOjIsImp0aSI6IjY0NjIyNTJjLWNiNDctNDZjMS04M2Q3LTNmYTFlYTFhZDE5YyIsImNsaWVudF9pZCI6IjZlamllanFuZ2RxdDBlbmNhbmlwb3BjZW0wIn0.rObgSccYnYtwmTkqL74RutEN-EpL_r6Jiat70hLCydY9eLT7ToJnSs5QrG8wlxOjUAnLTt4VDZ_youlK1Di0wAdws2Yt5ktvCUaKxrp5LjUWW21kYZhMQzBICVNjk4zZtoQAep8264DQqA3qq6RtzNNJAul6nORmY-iSvmzauwOW0xlO7EKdt725cEPfhF3xKBkRZ80Trk_u0q9x8sQ-E3KWwLzJAJo84zXfKVjnPPywqkWxe8ij8TpKpgrU1kwHs7btcwqCOGBWxk0Y-nqpqA4vcPXGN1mHVFxdMnTdR0_SpM39gWHc4GunTdnZcDRmuOdAtXtbudQRbmn-h5nrUQ`
    );
    const body = { tableName: tableName, column: columnName };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
      redirect: "follow",
    };
    let dataToFilter = await fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/ssr/filter`,
      requestOptions
    );

    let dataToFilterJson = await dataToFilter.json();
    console.log("data :", dataToFilterJson);
    return dataToFilterJson;
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "id",
      headerName: "Invoice Number",
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params) => {
          let jsonData = await getFilterData("invoices", "id");
          params.success(jsonData);
        },
      },
    },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: RecordStatus,
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params) => {
          let jsonData = await getFilterData("invoices", "status");
          params.success(jsonData);
        },
      },
    },
    {
      field: "ship_to",
      headerName: "Ship To",
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params) => {
          let jsonData = await getFilterData("invoices", "ship_to");
          params.success(jsonData);
        },
      },
    },
    {
      field: "ship_from",
      headerName: "Ship From",
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params) => {
          let jsonData = await getFilterData("invoices", "ship_to");
          params.success(jsonData);
        },
      },
    },
    {
      field: "invoice_date",
      headerName: "Invoice Date",
      filter: "agDateColumnFilter",
    },
    {
      field: "product_family",
      headerName: "Product Family",
      filterParams: {
        values: async (params) => {
          let jsonData = await getFilterData("invoices", "product_family");
          params.success(jsonData);
        },
      },
    },
    {
      field: "customer",
      headerName: "Customer",
      filterParams: {
        values: async (params) => {
          let jsonData = await getFilterData("invoices", "customer");
          params.success(jsonData);
        },
      },
    },
    {
      field: "vat_point_date",
      headerName: "VAT Point Date",
      filter: "agDateColumnFilter",
    },
    {
      field: "commodity_code",
      headerName: "Commodity Code",
      filterParams: {
        values: async (params) => {
          let jsonData = await getFilterData("invoices", "commodity_code");
          params.success(jsonData);
        },
      },
    },
    {
      field: "vat_supplier",
      headerName: "VAT Supplier",
      filterParams: {
        values: async (params) => {
          let jsonData = await getFilterData("invoices", "vat_supplier");
          params.success(jsonData);
        },
      },
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      filter: true,
      flex: 1,
    };
  }, []);

  //Client side rendering
  /*const onGridReady = (params) => {
    setGridApi(params.api);

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${user.user.signInUserSession.accessToken.jwtToken}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/invoice/view/all`,
      requestOptions
    )
      .then((resp) => resp.json())
      .then((data) => {
        setRowData(data);
      })
      .catch((error) => console.log("error", error));
  };*/

  const serverSideRendering = {
    getRows(params) {
      // called by the grid when more rows are required
      //getRows: params => {

      console.log(
        "Test of serverside rendering :",
        JSON.stringify(params.request, null, 1)
      );

      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${user.user.signInUserSession.accessToken.jwtToken}`
      );

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(params.request),
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/ssr/invoice/view/all`,
        requestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          console.log("last", resp);
          params.successCallback(resp.rows, resp.lastRow);
        })
        .catch((error) => {
          console.log("error", error);
          params.fail();
        });
    },
  };

  const serverSideDatasource = serverSideRendering;

  const deselect = useCallback((e) => {
    gridRef.current.api.deselectAll();
  });

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();

    const id = selectedRows.length === 1 ? selectedRows[0].id : "";

    navigate(`/item/invoice/${id}`);
  }, []);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          maxWidth: 225,
          width: 225,
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
    };
  }, []);

  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          <PaidOutlinedIcon
            fontSize="large"
            sx={{
              color: "#83b81a",
            }}
          />
          <p className={styles.pageTitle}>Invoices</p>
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            size="small"
            variant="contained"
            style={{ backgroundColor: "#F8F8F8", color: "black" }}
            onClick={deselect}
          >
            Deselect
          </Button>
          {/* <Button
            size="small"
            variant="contained"
            style={{ backgroundColor: "#F8F8F8", color: "black" }}
          >
            button2
          </Button>
          <Button
            size="small"
            variant="contained"
            style={{ backgroundColor: "#F8F8F8", color: "black" }}
          >
            button3
          </Button> */}
        </div>
      </div>

      <div style={{ width: "100%" }}>
        <div className="ag-theme-alpine" style={{ height: 500 }}>
          <div className={styles.maxDetailContainer}>
            <AgGridReact
              ref={gridRef}
              onCellClicked={cellClickedListener}
              onRowDoubleClicked={onSelectionChanged}
              //rowData={rowData} Client side rendering
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows={true}
              rowSelection="multiple"
              //onGridReady={onGridReady} Client side rendering
              enableRangeSelection={true}
              pagination={true}
              paginationPageSize={50}
              sideBar={sideBar}
              enableSorting={true}
              multiSortKey="ctrl"
              rowModelType="serverSide" //Need for SERVER SIDE REDERING
              serverSideStoreType="partial"
              serverSideDatasource={serverSideDatasource}
              serverSideSortOnServer={true}
              serverSideFilterOnServer={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Invoices;
