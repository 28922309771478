import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { render } from "react-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useParams } from "react-router-dom";
import { createUseStyles } from "react-jss";
import { LicenseManager } from "ag-grid-enterprise";
import OrderDocumentsComponent from "../Components/OrderDocumentsComponent";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import UserContext from "../UserContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import cc from "classcat";
import axios from "axios";
import { RecordStatus } from "../Components/CustomizeChip";
import { Chip } from "@mui/material";

LicenseManager.setLicenseKey(
  "For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-30_June_2022_[v2]_MTY1NjU0MzYwMDAwMA==05007f3f89f0719199ee98bb5bd2edd5"
);

const useStyles = createUseStyles({
  titleContainer: {
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
    marginTop: "15px",
    marginBottom: "15px",
  },

  pageTitle: {
    padding: "0px 0px 0px 10px",
    fontSize: "32px",
    marginBottom: 0,
  },

  pageSubTitle: {
    padding: "0px 0px 10px 20px",
    fontSize: "28px",
    // color: "#B3B2B3",
  },

  maxDetailContainer: {
    height: "60vh !important",
  },

  documentsTableContainer: {
    height: "40vh !important",
    marginTop: "0px",
  },
  descriptionContainer: {
    // flexDirection: "column",
    paddingLeft: "5px",
  },
  pageDescription: {
    color: "#B3B2B3",
    fontSize: "16px",
  },
  subHeaderContainer: {
    paddingLeft: "13px",
  },
  statusPosition:{
    position:"relative",
    paddingLeft: "15px",
    top: "5px",
    paddingRight: "5px"
  },
});

const Order = () => {
  let { orderId } = useParams();
  const styles = useStyles();
  const gridRef = useRef();
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const isLoading = useContext(UserContext);
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [userToken, setUserToken] = useState([]);
  const [columnDefs, setColumnDefs] = useState([
    {
      field: "order_number",
      headerName: "Order Number",
      cellRenderer: "agGroupCellRenderer",
    },
    { field: "type", headerName: "Order Type" },
    { field: "entity" },
    { field: "status", headerName: "Status", cellRenderer: RecordStatus},
  ]);
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
    };
  }, []);

  var requestOptions = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  };

  const cellClickedListener = useCallback((e) => {
    console.log("cellClicked", e);
  });

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    console.log("selectedRows", selectedRows)
    const id = selectedRows.length === 1 ? selectedRows[0].invoice_number : '';
    navigate(`/item/invoice/${id}`);
  }, []);

  useEffect(() => {
    if (!user.isLoading) {
      setUserToken(user.user.signInUserSession.accessToken.jwtToken);
    }
  }, [user.isLoading]);

  useEffect(() => {
    if (userToken) {
      fetchOrderData();
    }
  }, [userToken]);

  async function fetchOrderData() {
    const orderDataRequest = await axios.get(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/item/order/${orderId}`,
      requestOptions
    );

    for (let i = 0; i < orderDataRequest.data.length; i++) {
      setRowData(orderDataRequest.data[i].sub_orders);
    }

    for (let i = 0; i < orderDataRequest.data.length; i++) {
      setOrderData(orderDataRequest.data[i]);
    }
  }

  const detailCellRendererParams = useMemo(() => {
    return {
      // level 2 grid options
      detailGridOptions: {
        suppressRowClickSelection:"false",
        columnDefs: [
          {
            field: "line_number",
            headerName: "Line Number",
            cellRenderer: "agGroupCellRenderer",
            flex: 2,
          },
          {
            field: "status",
            flex: 0.7,
            cellRenderer: RecordStatus
          },
        ],
        defaultColDef: {
          flex: 1,
        },
        groupDefaultExpanded: 1,
        masterDetail: true,
        detailRowHeight: 240,
        detailRowAutoHeight: true,
        detailCellRendererParams: {
          // level 3 grid options
            detailGridOptions: {
            ref: gridRef,
            columnDefs: [
              {
                field: "sublineorderid",
                headerName: "Subline Number",
                cellRenderer: "agGroupCellRenderer",
              },
              { field: "invoice_number", headerName: "Invoice" },
              { field: "shipment" },
              { field: "forwarder", headerName: "LSP" },
              { field: "status", cellRenderer: RecordStatus },
            ],
            defaultColDef: {
              flex: 1,
            },
            rowSelection:"multiple",
            onCellClicked: cellClickedListener,
            onRowDoubleClicked: onSelectionChanged,
          },
          getDetailRowData: (params) => {
            params.successCallback(params.data.sub_lines);
          },
        },
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.lines);
        console.log(params);
      },
    };
  }, [isLoading]);

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.forEachNode(function (node) {
      node.setExpanded(node.id === "-1");
    });
  }, []);
  console.log(rowData);
  console.log(orderData);

  return (
    <div>
      <div className={styles.subHeaderContainer}>
        <div className={styles.titleContainer}>
          <div className={styles.titleIcon}>
            <ShoppingCartOutlinedIcon
              fontSize="large"
              sx={{
                color: "#83b81a",
              }}
            />
          </div>
          <h1 className={styles.pageTitle}>Order Chain - {orderData.id}</h1>
          <p className={styles.statusPosition}><RecordStatus value={orderData.status}/></p>
        </div>
        <div>
          <div className={styles.descriptionContainer}>
            {/* <p className={styles.pageDescription}>
              Orders included in this invoice
            </p> */}
          </div>
        </div>
      </div>
      <div style={containerStyle}>
        <div style={gridStyle} className="ag-theme-alpine">
          <div className={styles.maxDetailContainer}>
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              groupDefaultExpanded={1}
              masterDetail={true}
              detailCellRendererParams={detailCellRendererParams}
              detailRowAutoHeight={true}
              onFirstDataRendered={onFirstDataRendered}
            ></AgGridReact>
          </div>

          <div className={styles.documentsTableContainer}>
            <div>
              <div className={styles.subHeaderContainer}>
                <div className={styles.titleContainer}>
                  <div className={styles.titleIcon}>
                    <ArticleOutlinedIcon
                      fontSize="large"
                      sx={{
                        color: "#83b81a",
                      }}
                    />
                  </div>
                  <h1 className={styles.pageTitle}>Documents</h1>
                </div>
                <div>
                  <div className={styles.descriptionContainer}>
                    <p className={styles.pageDescription}>
                      Documents Required and Available for this order, including
                      invoicing and shipment related documents.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <OrderDocumentsComponent orderId={orderId} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Order;
