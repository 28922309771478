import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { createUseStyles } from "react-jss";
import { CircularProgress } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import axios from "axios";
import UserContext from "../UserContext";
import { useContext } from "react";
import { DocumentStatus, RecordStatus } from "../Components/CustomizeChip";
import { useNavigate } from "react-router-dom";

const useStyles = createUseStyles({
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },

  pageTitle: {
    padding: "10px 0px 5px 15px",
    // borderBottom: " 3px solid #F8F8F8",
    // color: "#B3B2B3",
    fontSize: "32px",
    marginBottom: 0,
  },

  pageSubTitle: {
    padding: "10px 0px 0px 15px",
    fontSize: "28px",
    // color: "#B3B2B3",
  },

  maxDetailContainer: {
    height: "30vh !important",
  },

  documentsTableContainer: {
    height: "40vh !important",
  },
  errorHandlerContainer: {
    // height: "0vh !important",
    alignItems: "center",
    marginTop: "15px",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },

  titleIcon: {},
  descriptionContainer: {
    // flexDirection: "column",
    paddingLeft: "5px",
  },
  pageDescription: {
    color: "#B3B2B3",
  },
  subHeaderContainer: {
    paddingLeft: "15px",
  },
  statusPosition:{
    position:"relative",
    paddingLeft: "15px",
    top: "5px",
    paddingRight: "5px"
  },
});

const Shipment = () => {
  let { shipmentId } = useParams();
  const styles = useStyles();
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const isLoading = useContext(UserContext);
  const [userToken, setUserToken] = useState();
  const [data, setData] = useState({});
  const [ordersData, setOrdersData] = useState({});
  const [documentsData, setDocumentsData] = useState({});
  const [loading, setLoading] = useState(true);
  const [isErrorCode500, setErrorCode500] = useState(false);
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  var requestOptions = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  };

  useEffect(() => {
    if (!user.isLoading) {
      setUserToken(user.user.signInUserSession.accessToken.jwtToken);
    }
  }, [user.isLoading]);

  const gridRef = useRef();

  const cellClickedListener = useCallback((e) => {
    console.log("cellClicked", e);
  });

  async function fetchShipmentData() {
    const shipmentDataRequest = await axios.get(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/item/shipment/${shipmentId}`,
      requestOptions
    );

    setData(shipmentDataRequest.data);

    const shipmentOrdersRequest = await axios.get(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/item/shipment/${shipmentId}/orders`,
      requestOptions
    );

    setOrdersData(shipmentOrdersRequest.data);

    const shipmentDocumentsRequest = await axios
      .get(
        `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/item/shipment/${shipmentId}/documents`,
        requestOptions
      )
      .catch((error) => {
        console.log("this is the error");
        if (error.response.status == 500) {
          console.log("ERROR CODE 500");
          setLoading(false);
          setErrorCode500(true);
        } else {
          console.log("DUNNO ERROR CODE");
        }
      });
    setDocumentsData(shipmentDocumentsRequest.data);
    setLoading(false);
  }

  useEffect(() => {
    if (userToken) {
      fetchShipmentData();
    }
  }, [userToken]);

  const onSelectionChangedDocument = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    console.log("selectedRows", selectedRows)
    const id = selectedRows.length === 1 ? selectedRows[0].id : "";
    navigate(`/documents/${id}`);
  }, []);
  
  const onSelectionChangedOrder = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    console.log("selectedRows", selectedRows)
    const id = selectedRows.length === 1 ? selectedRows[0].order_number : "";
    navigate(`/item/order/${id}`);
  }, []);

  const documentsColumnDefs = useMemo(
    () => [
      { field: "filename", flex: 1 },
      { field: "document_type", headerName: "Document Type", flex: 1 },
      { field: "status", flex: 1, cellRenderer: DocumentStatus },
    ],
    []
  );
  const ordersColumnDefs = useMemo(
    () => [
      { field: "order_number", headerName: "Order Number", flex: 1 },
      { field: "order_type", headerName: "Order Type", flex: 1 },
      { field: "entity", flex: 1 },
      { field: "subline_id", flex: 1, headerName: "Subline Number" },
    ],
    []
  );

  // never changes, so we can use useMemo
  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
    }),
    []
  );

  console.log("---- shipment data ----");
  console.log(data);
  console.log("---- shipment orders data ----");
  console.log(ordersData);
  console.log("---- shipment documents data ----");
  console.log(documentsData);

  console.log("---- this is isErrorCode500 ----");
  console.log(isErrorCode500);

  if (loading) {
    return (
      <div className="invoices">
        <CircularProgress style={{ color: "gray" }} size={70} />
      </div>
    );
  }
  return (
    <>
      <p className={styles.pageTitle}>Shipment: {shipmentId} <RecordStatus style={styles.statusPosition} value={data[0].status}/> </p>
      
      <div>
        <div>
          <div className={styles.subHeaderContainer}>
            <div className={styles.titleContainer}>
              <div className={styles.titleIcon}>
                <ArticleOutlinedIcon
                  fontSize="large"
                  sx={{
                    color: "#83b81a",
                  }}
                />
              </div>
              <h1 className={styles.pageTitle}>Documents</h1>
            </div>
            <div>
              <div className={styles.descriptionContainer}>
                <p className={styles.pageDescription}>
                  Required and Available for this shipment
                </p>
              </div>
            </div>
          </div>
        </div>

        {isErrorCode500 ? (
          <div className={styles.errorHandlerContainer}>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              <strong>Documents table is unavailable at the moment</strong> -
              Please be patient while we work on this issue. Thank you!
            </Alert>
          </div>
        ) : (
          <div style={containerStyle}>
            <div style={gridStyle} className="ag-theme-alpine">
              <div className={styles.maxDetailContainer}>
                <AgGridReact
                  ref={gridRef}
                  onCellClicked={cellClickedListener}
                  onRowDoubleClicked={onSelectionChangedDocument}
                  className="ag-theme-alpine"
                  animateRows="true"
                  columnDefs={documentsColumnDefs}
                  defaultColDef={defaultColDef}
                  enableRangeSelection="true"
                  rowData={documentsData}
                  rowSelection="multiple"
                  suppressRowClickSelection="true"
                  suppressColumnVirtualisation="true"
                  pagination={true}
                  paginationPageSize={50}
                />
              </div>
            </div>
          </div>
        )}

        <div>
          <div className={styles.subHeaderContainer}>
            <div className={styles.titleContainer}>
              <div className={styles.titleIcon}>
                <PaidOutlinedIcon
                  fontSize="large"
                  sx={{
                    color: "#83b81a",
                  }}
                />
              </div>
              <h1 className={styles.pageTitle}>Orders</h1>
            </div>
            <div>
              <div className={styles.descriptionContainer}>
                <p className={styles.pageDescription}>
                  Orders included in this shipment
                </p>
              </div>
            </div>
          </div>
        </div>
        <div style={containerStyle}>
          <div style={gridStyle} className="ag-theme-alpine">
            <div className={styles.maxDetailContainer}>
              <AgGridReact
                ref={gridRef}
                onCellClicked={cellClickedListener}
                onRowDoubleClicked={onSelectionChangedOrder}
                className="ag-theme-alpine"
                animateRows="true"
                columnDefs={ordersColumnDefs}
                defaultColDef={defaultColDef}
                enableRangeSelection="true"
                rowData={ordersData}
                rowSelection="multiple"
                //suppressRowClickSelection="true"
                suppressColumnVirtualisation="true"
                pagination={true}
                paginationPageSize={50}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Shipment;
