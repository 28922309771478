//imports
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { createUseStyles } from "react-jss";
import Select from "react-select";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import { Document as PDFDocument, Page, pdfjs } from "react-pdf";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { ProgressBar } from "react-bootstrap";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import UserContext from "../UserContext";
import { useContext } from "react";
import BravaViewer from "../Components/BravaViewer";
import "bootstrap/dist/css/bootstrap.min.css";

//mock data
import docTypes from "./docTypes.json";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";

//styles
const useStyles = createUseStyles({
  headerDocumentStatus: {
    height: "80px",
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 20px",
    boxShadow: "3px 3px 6px 2px #E2E0E2",
    marginBottom: "50px",
  },

  statusStep: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  statusStepText: {
    marginBottom: 0,
  },
  circleActive: {
    borderRadius: "50%",
    width: "34px",
    height: "34px",
    padding: "6px",
    background: "#83b81a",
    color: "#fff",
    textAlign: "center",
    marginRight: "10px",
  },
  circleNotActive: {
    borderRadius: "50%",
    width: "34px",
    height: "34px",
    padding: "6px",
    background: "#E2E0E2",
    color: "#5E6A71",
    textAlign: "center",
    marginRight: "10px",
  },

  headerStatusSeparator: {
    width: "800px",
    height: "2px",
    background: "#E2E0E2",
  },

  optionalText: {
    color: "#B3B2B3",
  },

  pageDivider: {
    display: "flex",
    position: "absolute",
    // justifyContent: "space-between",
  },

  documentInfoContainer: {
    paddingLeft: "40px",
    // minWidth: "48%",
  },
  documentName: {
    fontSize: "34px",
  },

  documentInfo: {
    fontSize: "18px",
    color: "#B3B2B3",
    // flex:2
    minWidth: "150px",
  },

  documentInfoItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  separator: {
    height: "30px",
    width: "2px",
    background: "#E2E0E2",
  },

  tableValue: {
    fontSize: "18px",
  },

  tableRow: {
    paddingBottom: "10px",
  },

  table: {
    borderCollapse: "separate",
    borderSpacing: "0 1em",
  },

  formContainer: {
    paddingBottom: "10px",
  },

  formContainerSelect: {
    maxWidth: "450px",
    paddingTop: "10px",
  },

  formInfoLabel: {
    marginBottom: "10px",
    fontSize: "18px",
    color: "#B3B2B3",
  },

  buttonsGroup: {
    display: "flex",
    marginTop: "20px",
  },

  button: {
    padding: "0px 10px 0px 0px",
  },
  pdfContainer: {
    marginRight: "50px",
    maxWidth: "800px",
    // minHeight: "1000px",
    textAlign: "center",
  },

  invalidProcessingLoaderContainer: {
    // position: "absolute",
    display: "flex",
    top: "50%",
    alignItems: "center",
    justifyContent: "center",
  },
  requiredAttributeInfo: {
    // display: "flex",
    // justifyContent: "space-between",
  },

  requiredAttributeField: {
    fontSize: "18px",
    color: "#B3B2B3",
    // flex: "2",
    minWidth: "350px",
    marginTop: "20px",
    marginBottom: "10px",
  },

  requiredAtrributeValue: {
    fontSize: "18px",

    "& label.Mui-focused": {
      color: "#83b81a",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#83b81a",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        // borderColor: "#83b81a",
      },
      "&:hover fieldset": {
        borderColor: "#83b81a",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#83b81a",
      },
    },
  },

  progressBarStyle: {
    backgroundColor: "#83b81a",
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  minHeight: "250px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Document = () => {
  //HOOKS
  const styles = useStyles();
  const navigate = useNavigate();
  const { control, handleSubmit, register } = useForm();
  let { documentId } = useParams();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [docType, setDocType] = useState(null);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [docTemplateAndData, setDocTemplateAndData] = useState([]);
  const [documentTypeName, setDocumentTypeName] = useState("");
  const [documentTemplateName, setDocumentTemplateName] = useState("");
  const [docTemplate, setDocTemplate] = useState(null);
  const [docList, setDocList] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [data, setData] = useState([]);
  const [channelType, setChannelType] = useState("");
  const [objectType, setObjectType] = useState("");
  const [pdfData, setPDFData] = useState("");
  const [getRequiredAttributes, setRequiredAttributes] = useState([]);
  // const [getInvoiceObjectSelectionList, setInvoiceSelectionList] = useState([]);
  const [extractedAttributes, setExtractedAttributes] = useState([]);
  const [requiredAttributeValue, setRequiredAttributeValue] = useState("");
  const [isDocTypeSelected, setIsDocTypeSelected] = useState(false);
  const [isDocTemplateSelected, setIsDocTemplateSelected] = useState(false);
  const [isClassify, setIsClassify] = useState(false);
  const [isExtraction, setIsExtraction] = useState(false);
  const [isMatching, setIsMatching] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [postResponse, setPostResponse] = useState({});
  const [postResponseFetched, setPostResponseFetched] = useState(false);
  const [invObjID, setInvObjID] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const user = useContext(UserContext);
  const isLoading = useContext(UserContext);
  const [userToken, setUserToken] = useState();

  const [countOfProgess, setCountOfProgess] = useState(0);
  const [isProcessed, setIsProcessed] = useState(false);
  const [open, setOpen] = useState(false);

  const [stage, setStage] = useState("");

  const [invoiceSelectionList, setInvoiceSelectionList] = useState([]);

  const ref = useRef();

  const handleClassifyModalOpen = () => {
    setOpen(true);
    setTimeout(() => setIsProcessing(true), 3000);
    postDocumentTypeAndTemplate();
    setTimeout(() => {
      stage === "Classify" ? setIsClassify(true) : setIsClassify(false);
      stage === "Extract" ? setIsExtraction(true) : setIsExtraction(false);
      stage === "Match" ? setIsMatching(true) : setIsMatching(false);
      stage === "Completed" ? setIsCompleted(true) : setIsCompleted(false);
      setTimeout(() => {
        handleClose();
      }, 500);
    }, 3500);
    //
  };

  const handleReqAttrValueChange = (e) => {
    setRequiredAttributeValue(e.target.value);
  };

  if (isProcessing) {
    window.location.reload();
  }
  const handleExtractionModalOpen = () => {
    setOpen(true);
    setTimeout(() => setIsProcessing(true), 3000);
    setTimeout(() => {
      stage === "Classify" ? setIsClassify(true) : setIsClassify(false);
      stage === "Extract" ? setIsExtraction(true) : setIsExtraction(false);
      stage === "Match" ? setIsMatching(true) : setIsMatching(false);
      stage === "Completed" ? setIsCompleted(true) : setIsCompleted(false);
      setTimeout(() => {
        handleClose();
      }, 500);
    }, 3500);
  };

  const handleMatchingModalOpen = () => {
    setOpen(true);
    setTimeout(() => setIsProcessing(true), 3000);
    setTimeout(() => {
      stage === "Classify" ? setIsClassify(true) : setIsClassify(false);
      stage === "Extract" ? setIsExtraction(true) : setIsExtraction(false);
      stage === "Match" ? setIsMatching(true) : setIsMatching(false);
      stage === "Completed" ? setIsCompleted(true) : setIsCompleted(false);
      setTimeout(() => {
        handleClose();
      }, 500);
    }, 3500);
  };

  const handleClose = () => {
    setOpen(false);
    setIsProcessing(false);
    // window.location.reload();
  };

  const config = {
    headers: {
      ticket: "96267EE23097fdfd31EEZ341657107125fsffs",
    },
  };

  var requestOptions = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  };

  const fetchDocumentData = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/document/${documentId}`,
        requestOptions
      )
      .then((res) => {
        const data = res.data;
        setData(data);
        console.log("fetchDocumentData REQUEST -> DATA SET");
        console.log(res.data);
        //
        const channelType = res.data.channel;
        setChannelType(channelType);
        const objectType = res.data.object_type;
        setObjectType(objectType);
        console.log("fetchDocumentData REQUEST -> CHANNEL SET");
        console.log(channelType);
      })
      .then(() => {
        console.log("fetchDocumentData REQUEST -> START THE POST REQUEST");

        axios({
          method: "post",
          url: `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/document/type`,
          data: {
            channel: `${channelType}`,
          },
          headers: {
            "Content-Type": "application/json",
            // ticket: "96267EE23097fdfd31EEZ341657107125fsffs",
            Authorization: `Bearer ${userToken}`,
          },
        }).then(
          (response) => {
            setPostResponse(response.data);
          },
          (error) => {
            console.log(error);
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchPDFData = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/document/${documentId}/file`,
        requestOptions
      )
      .then((res) => {
        const data = res.data;
        setPDFData(data);
        console.log("======= THIS IS REQ PDF DATA =======");
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchDocumentStatus = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/document/${documentId}/status`,
        requestOptions
      )
      .then((res) => {
        if (res.data.stage === "Classify") {
          setIsClassify(true);
          setIsMatching(false);
          setIsExtraction(false);
          setIsCompleted(false);
        }
        if (res.data.stage === "Extract") {
          setIsExtraction(true);
          setIsClassify(false);
          setIsMatching(false);
          setIsCompleted(false);
        }
        if (res.data.stage === "Match") {
          setIsMatching(true);
          setIsClassify(false);
          setIsExtraction(false);
          setIsCompleted(false);
        }
        if (res.data.stage === "Completed") {
          setIsCompleted(true);
          setIsClassify(false);
          setIsMatching(false);
          setIsExtraction(false);
        }

        setStage(res.data.stage);
        console.log("----- IS PROCESSING -----");
        console.log(res.data.processing);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchRequiredAttributes = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/document/${documentId}/attributes/required`,
        requestOptions
      )
      .then((res) => {
        console.log("Required Attributes Fetched");
        console.log(res);
        setRequiredAttributes(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const fetchInvoiceObjectSelectionList = () => {
  //   axios
  //     .get(
  //       `${
  //         process.env.REACT_APP_API_GATEWAY_BASE_URL
  //       }/item/${objectType.toLowerCase()}/open`,
  //       // "https://fsyajg0qfh.execute-api.eu-west-3.amazonaws.com/Dev/ssr/documents",
  //       requestOptions
  //     )
  //     .then((res) => {
  //       console.log("Invoice Object Selection List Fetched");
  //       console.log(res.data);
  //       // setRequiredAttributes(res.data);
  //       setInvoiceSelectionList(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const fetchInvoiceObjectSelectionList = async () => {
    try {
      const res = await fetch(
        `${
          process.env.REACT_APP_API_GATEWAY_BASE_URL
        }/item/${objectType.toLowerCase()}/open`,
        requestOptions
      );
      const data = await res.json();
      const invoiceData = JSON.parse(data.body);
      setInvoiceSelectionList(invoiceData.map((item) => item.id));
    } catch (err) {
      console.error(err);
    }
  };

  //FUNCTIONS

  const handleDocTypeChange = (obj) => {
    // for (let i = 0; i < Object.keys(obj).length; i++) {
    //   console.log(Object.values(obj)[i]);
    //   setDocType(Object.values(obj)[i]);
    // }

    // for (let i = 0; i < Object.keys(obj).length; i++) {
    //   console.log(Object.values(obj)[i]);
    //   setDocList(Object.values(obj)[i].document_templates);
    //  }

    //  console.log

    console.log("---- THIS IS HANDLE DOCTYPE CHANGE ### OBJECT ###-----");
    console.log(obj);

    console.log("---- THIS IS HANDLE DOCTYPE CHANGE -----");
    setDocType(obj);
    setDocumentTypeName(obj.name);
    setDocList(obj.docTemplates);
    setDocTemplate(null);

    console.log("---- THIS IS DOCTYPE VALUE -----");
    console.log(docType);
  };

  const handleChange = (obj) => {
    setDocTemplate(obj);
    setDocumentTemplateName(obj.name);
  };

  const handleDropdownClick = () => {
    console.log(postResponse);

    if (stage === "Match") {
      fetchInvoiceObjectSelectionList();
    }
  };

  const handleObjInvIdChange = (obj) => {
    setInvObjID(obj.id);
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  const postDocumentTypeAndTemplate = () => {
    fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/document/${documentId}/classify`,
      {
        method: "POST",
        body: JSON.stringify({
          document_type: documentTypeName,
          document_template: documentTemplateName,
        }),
        headers: { Authorization: `Bearer ${userToken}` },
      }
    );
  };
  // const config = {
  //   headers: {
  //     ticket: "96267EE23097fdfd31EEZ341657107125fsffs",
  //   },
  // };

  // async function fetchDocumentObjectType() {
  //   const documentObjectType = await axios({
  //     method: "GET",
  //     url: url,
  //     headers: {
  //       "Content-Type": "application/json",
  //       ticket: "96267EE23097fdfd31EEZ341657107125fsffs",
  //     },
  //   });
  //   console.log('this is the LOG')

  //   console.log(documentObjectType);
  // }

  async function fetchDocumentTypeAndTemplateData() {
    const documentTypeAndTemplateData = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/document/type`,
      data: {
        // channel: `"${data.channel}"`,
        channel: channelType,
      },
      headers: {
        "Content-Type": "application/json",
        // ticket: "96267EE23097fdfd31EEZ341657107125fsffs",
        Authorization: `Bearer ${userToken}`,
      },

      // let payload = { channel: channelType };
      // let headers = { ticket: "96267EE23097fdfd31EEZ341657107125fsffs" };

      // let res = await axios.post(
      //   "https://tm8vcxydzi.execute-api.eu-central-1.amazonaws.com/dev/document/documenttype",
      //   payload,
      //   config
      //   // headers: {
      //   //   "Content-Type": "application/json",
      //   //   ticket: "96267EE23097fdfd31EEZ341657107125fsffs",
      //   // },
      // );

      // console.log("===== this is document data and template data RESULT =====");
      // let result = res.data;

      // console.log(result);
    });

    console.log(documentTypeAndTemplateData.data);

    setDocTemplateAndData(documentTypeAndTemplateData.data);
  }

  const onSubmit = (data) => {
    console.log("----- THE DOCUMENT TYPE IS -----");
    console.log(documentTypeName);

    console.log("----- THE DOCUMENT TEMPLATE IS -----");
    console.log(documentTemplateName);

    fetchDocumentStatus();

    handleClassifyModalOpen();
  };

  const onViewerClick = (data) => {};

  //======================= DEPRECATED =======================
  const postRequiredAttributes = () => {
    fetch(
      `https://tm8vcxydzi.execute-api.eu-central-1.amazonaws.com/dev/document/${documentId}/extract`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: { ticket: "96267EE23097fdfd31EEZ341657107125fsffs" },
      }
    );
  };
  //===========================================================

  const onExtractionSubmit = (data) => {
    console.log("------- THIS IS EXTRACTION SUBMIT -------");
    fetchDocumentStatus();
    fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/document/${documentId}/extract`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: { Authorization: `Bearer ${userToken}` },
      }
    );
    console.log(JSON.stringify(data));

    handleExtractionModalOpen();
  };

  const onMatchingSubmit = (data) => {
    console.log("------- THIS IS EXTRACTION SUBMIT -------");
    console.log(JSON.stringify(invObjID));
    fetchDocumentStatus();
    fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/document/${documentId}/match`,
      {
        method: "POST",
        body: JSON.stringify(invObjID),
        headers: { Authorization: `Bearer ${userToken}` },
      }
    );

    handleMatchingModalOpen();
  };

  useEffect(() => {
    if (!user.isLoading) {
      setUserToken(user.user.signInUserSession.accessToken.jwtToken);
    }
  }, [user.isLoading]);

  useEffect(() => {
    if (userToken) {
      if (stage === "Extract" || stage === "Match" || stage === "Completed") {
        fetchRequiredAttributes();
      }
    }
  }, [userToken, stage]);

  useEffect(() => {
    if (userToken) {
      fetchDocumentData();
      fetchDocumentStatus();
      // fetchPDFData();
      // console.log("===== PDF DATA ======");
      // console.log(pdfData);
    }

    if (documentTypeName !== null) {
      console.log("docType is selected");
      setIsDocTypeSelected(true);
    } else {
      console.log("docType is not selected");
    }

    if (docTemplate !== null) {
      setIsDocTemplateSelected(true);
    }
  }, [userToken, docType, docTemplate]);

  useEffect(() => {
    if (userToken) {
      fetchDocumentTypeAndTemplateData();
    }
  }, [userToken, channelType, pdfData]);
  // fetchDocumentTypeAndTemplateData();

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  console.log("----- this is doc types ------");
  console.log(docTypes);
  console.log("----- this is doc type ------");
  console.log(docType);
  console.log("----- this is doc list ------");
  console.log(docList);
  console.log("----- this is doc list ------");
  console.log(docTemplate);

  console.log("====== this is the array =======");
  const newArray = Object.values(docTemplateAndData);

  console.log(newArray);

  console.log(docList);

  console.log(
    "======#######====== THIS IS THE DOCUMENT TEMPLATE AND DATA ======#######======"
  );
  console.log(docTemplateAndData);

  console.log(
    "======#######====== THIS IS THE OBJECT TYPE ======#######======"
  );
  console.log(objectType);

  console.log(
    "======#######====== THIS IS THE STAGE OF THE DOCUMENT ======#######======"
  );
  console.log(data);

  let ot_url_download = data.s3_id !== undefined ? data.s3_id : "";
  let ot_url_rends =
    data.s3_id !== undefined
      ? data.s3_id.replace("download", "renditions")
      : "";
  console.log(ot_url_download);
  console.log(ot_url_rends);
  if (ot_url_download != "") {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${window.accessToken} `);
    myHeaders.append("Accept", "application/hal+json");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      publicationVersion: "1.0.0",
      policy: {
        namespace: "opentext.publishing.brava",
        name: "SimpleBravaView",
        version: "1.x",
      },
      featureSettings: [
        {
          feature: {
            namespace: "opentext.publishing.sources",
            name: "LoadSources",
          },
          path: "/documents",
          value: [
            {
              url: ot_url_download,
              formatHint: "pdf",
              filenameHint: "AdminGuide",
            },
          ],
        },
        {
          feature: {
            namespace: "opentext.publishing.execution",
            name: "SetPublishingTarget",
          },
          path: "/publishingTarget",
          value: ot_url_rends,
        },
      ],
    });

    var OTrequestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://na-1-dev.api.opentext.com/publication/api/v1/publications",
      OTrequestOptions
    )
      .then((response) => response.text())
      .then((result) => (window.publication = JSON.parse(result)))
      .catch((error) => console.log("error", error));
  }
  const placeholderText = `Select ${objectType} ID`;
  return (
    <>
      <div className={styles.headerDocumentStatus}>
        <div className={styles.statusStep}>
          <span
            className={
              isClassify ? styles.circleActive : styles.circleNotActive
            }
          >
            1
          </span>
          <div>
            <h3 className={styles.statusStepText}>Classify</h3>
          </div>
        </div>
        <span className={styles.headerStatusSeparator}></span>
        <div className={styles.statusStep}>
          <span
            className={
              isExtraction ? styles.circleActive : styles.circleNotActive
            }
          >
            2
          </span>
          <h3 className={styles.statusStepText}>Extract</h3>
        </div>
        <span className={styles.headerStatusSeparator}></span>
        <div className={styles.statusStep}>
          <span
            className={
              isMatching ? styles.circleActive : styles.circleNotActive
            }
          >
            3
          </span>
          <h3 className={styles.statusStepText}>Match</h3>
        </div>
        <span className={styles.headerStatusSeparator}></span>
        <div className={styles.statusStep}>
          <span
            className={
              isCompleted ? styles.circleActive : styles.circleNotActive
            }
          >
            4
          </span>
          <h3 className={styles.statusStepText}>Completed</h3>
        </div>
      </div>

      {isClassify ? (
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.pageDivider}>
              <div className={styles.documentInfoContainer}>
                <p className={styles.documentName}>{data.filename}</p>
                <table className={styles.table}>
                  <tbody>
                    <tr>
                      <td>
                        <p className={styles.documentInfo}>Filetype: </p>
                      </td>
                      <td style={{ paddingRight: "10px" }} />
                      <td>
                        <p className={styles.tableValue}>{data.extension}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className={styles.documentInfo}>Size: </p>
                      </td>
                      <td />
                      <td>
                        <p className={styles.tableValue}>
                          {data.filesize / 1000} kb
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className={styles.documentInfo}>Channel Type: </p>
                      </td>
                      <td />
                      <td>
                        <p className={styles.tableValue}>{data.channel_type}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className={styles.documentInfo}>Channel: </p>
                      </td>
                      <td />
                      <td>
                        <p className={styles.tableValue}>{data.channel}</p>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <p className={styles.documentInfo}>Document Date: </p>
                      </td>
                      <td />
                      <td>
                        <p className={styles.tableValue}>
                          {data.document_date}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  className={styles.formContainer}
                  onClick={handleDropdownClick}
                >
                  <label className={styles.formInfoLabel}>Document Type</label>

                  {/* <Select options={documentTypes} /> */}

                  <Select
                    className={styles.formContainerSelect}
                    placeholder="Select Document Type"
                    value={docType}
                    // options={documentTypes}
                    options={newArray}
                    onChange={handleDocTypeChange}
                    getOptionLabel={(x) => x.name}
                    getOptionValue={(x) => x.name}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#CCF88A",
                        primary: "#83B81B",
                      },
                    })}
                  />
                </div>
                <div className={styles.formContainer}>
                  <label className={styles.formInfoLabel}>
                    Document Template
                  </label>
                  <Select
                    className={styles.formContainerSelect}
                    placeholder="Select Document Template"
                    value={docTemplate}
                    options={docList}
                    onChange={handleChange}
                    getOptionLabel={(x) => x.name}
                    getOptionValue={(x) => x.name}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#CCF88A",
                        primary: "#83B81B",
                      },
                    })}
                  />
                </div>
                <div className={styles.buttonsGroup}>
                  <div className={styles.button}>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{ background: "#83B81B", minWidth: "150px" }}
                      disabled={!isDocTypeSelected || !isDocTemplateSelected}
                    >
                      COMPLETE
                    </Button>
                  </div>
                  <div>
                    <Button
                      variant="outlined"
                      sx={{
                        color: "#83B81B",
                        borderColor: "#83B81B",
                        minWidth: "150px",
                      }}
                      onClick={() => navigate("/documents")}
                    >
                      CLOSE
                    </Button>
                  </div>
                </div>
              </div>

              <div>
                {/* <div className={styles.pdfContainer}>
                <PDFDocument
                  file={pdfData}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page pageNumber={pageNumber} width={800} height={850} />
                </PDFDocument>
              </div> */}
              </div>
            </div>
          </form>
          <BravaViewer />
        </div>
      ) : isExtraction ? (
        <div>
          <form onSubmit={handleSubmit(onExtractionSubmit)}>
            <div className={styles.pageDivider}>
              <div className={styles.documentInfoContainer}>
                <p className={styles.documentName}>{data.filename}</p>
                <table className={styles.table}>
                  <tbody>
                    <tr>
                      <td>
                        <p className={styles.documentInfo}>Filetype: </p>
                      </td>
                      <td style={{ paddingRight: "10px" }} />
                      <td>
                        <p className={styles.tableValue}>{data.extension}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className={styles.documentInfo}>Size: </p>
                      </td>
                      <td />
                      <td>
                        <p className={styles.tableValue}>
                          {data.filesize / 1000} kb
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className={styles.documentInfo}>Channel Type: </p>
                      </td>
                      <td />
                      <td>
                        <p className={styles.tableValue}>{data.channel_type}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className={styles.documentInfo}>Channel: </p>
                      </td>
                      <td />
                      <td>
                        <p className={styles.tableValue}>{data.channel}</p>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <p className={styles.documentInfo}>Document Date: </p>
                      </td>
                      <td />
                      <td>
                        <p className={styles.tableValue}>
                          {data.document_date}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className={styles.documentInfo}>Document Type: </p>
                      </td>
                      <td />
                      <td>
                        <p className={styles.tableValue}>
                          {data.document_type}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className={styles.documentInfo}>
                          Document Template:{" "}
                        </p>
                      </td>
                      <td />
                      <td>
                        <p className={styles.tableValue}>
                          {data.document_template}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div>
                  {getRequiredAttributes.map((reqAttr) => (
                    <div
                      className={styles.requiredAttributeInfo}
                      key={reqAttr.field}
                    >
                      <p className={styles.requiredAttributeField}>
                        {reqAttr.field}
                      </p>
                      {reqAttr.value === "" ? (
                        <TextField
                          className={styles.requiredAtrributeValue}
                          required
                          id="outlined-required"
                          label="Required"
                          fullWidth
                          size="small"
                          {...register(reqAttr.field)}
                        />
                      ) : (
                        <TextField
                          className={styles.requiredAtrributeValue}
                          id="outlined-read-only-input"
                          label="Read Only"
                          defaultValue={reqAttr.value}
                          fullWidth
                          size="small"
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      )}
                    </div>
                  ))}
                </div>
                <div className={styles.buttonsGroup}>
                  <div className={styles.button}>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{ background: "#83B81B", minWidth: "150px" }}
                    >
                      COMPLETE
                    </Button>
                  </div>
                  <div>
                    <Button
                      variant="outlined"
                      sx={{
                        color: "#83B81B",
                        borderColor: "#83B81B",
                        minWidth: "150px",
                      }}
                      onClick={() => navigate("/documents")}
                    >
                      CLOSE
                    </Button>
                  </div>
                </div>
              </div>

              <div>
                {/* <div className={styles.pdfContainer}>
                <PDFDocument
                  file={pdfData}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page pageNumber={pageNumber} width={800} height={850} />
                </PDFDocument>
              </div> */}
              </div>
            </div>
          </form>
          <BravaViewer />
        </div>
      ) : isMatching ? (
        <div>
          <form onSubmit={handleSubmit(onMatchingSubmit)}>
            <div className={styles.pageDivider}>
              <div className={styles.documentInfoContainer}>
                <p className={styles.documentName}>{data.filename}</p>
                <table className={styles.table}>
                  <tbody>
                    <tr>
                      <td>
                        <p className={styles.documentInfo}>Filetype: </p>
                      </td>
                      <td style={{ paddingRight: "10px" }} />
                      <td>
                        <p className={styles.tableValue}>{data.extension}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className={styles.documentInfo}>Size: </p>
                      </td>
                      <td />
                      <td>
                        <p className={styles.tableValue}>
                          {data.filesize / 1000} kb
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className={styles.documentInfo}>Channel Type: </p>
                      </td>
                      <td />
                      <td>
                        <p className={styles.tableValue}>{data.channel_type}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className={styles.documentInfo}>Channel: </p>
                      </td>
                      <td />
                      <td>
                        <p className={styles.tableValue}>{data.channel}</p>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <p className={styles.documentInfo}>Document Date: </p>
                      </td>
                      <td />
                      <td>
                        <p className={styles.tableValue}>
                          {data.document_date}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className={styles.documentInfo}>Document Type: </p>
                      </td>
                      <td />
                      <td>
                        <p className={styles.tableValue}>
                          {data.document_type}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className={styles.documentInfo}>
                          Document Template:{" "}
                        </p>
                      </td>
                      <td />
                      <td>
                        <p className={styles.tableValue}>
                          {data.document_template}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      {getRequiredAttributes.map((reqAttr) => (
                        <>
                          <td>
                            <p className={styles.documentInfo}>
                              {reqAttr.field}:{" "}
                            </p>
                          </td>
                          <td />
                          <td>
                            <p className={styles.tableValue}>{reqAttr.value}</p>
                          </td>
                        </>
                      ))}
                    </tr>
                  </tbody>
                </table>

                <div>
                  <div
                    className={styles.formContainer}
                    onClick={handleDropdownClick}
                  >
                    <label className={styles.formInfoLabel}>
                      {objectType} ID:
                    </label>

                    {/* <Select
                      className={styles.formContainerSelect}
                      placeholder={placeholderText}
                      // value={docTemplate}
                      options={getInvoiceObjectSelectionList}
                      onChange={handleObjInvIdChange}
                      getOptionLabel={(x) => x}
                      getOptionValue={(x) => x}
                      // options={getInvoiceObjectSelectionList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: "#CCF88A",
                          primary: "#83B81B",
                        },
                      })}
                    /> */}
                    <Select
                      className={styles.formContainerSelect}
                      placeholder={placeholderText}
                      options={invoiceSelectionList.map((id) => ({
                        value: id,
                        label: id,
                      }))}
                      onChange={handleObjInvIdChange}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: "#CCF88A",
                          primary: "#83B81B",
                        },
                      })}
                    />
                  </div>
                </div>

                <div className={styles.buttonsGroup}>
                  <div className={styles.button}>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{ background: "#83B81B", minWidth: "150px" }}
                    >
                      COMPLETE
                    </Button>
                  </div>
                  <div>
                    <Button
                      variant="outlined"
                      sx={{
                        color: "#83B81B",
                        borderColor: "#83B81B",
                        minWidth: "150px",
                      }}
                      onClick={() => navigate("/documents")}
                    >
                      CLOSE
                    </Button>
                  </div>
                </div>
              </div>

              <div>
                {/* <div className={styles.pdfContainer}>
                <PDFDocument
                  file={pdfData}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page pageNumber={pageNumber} width={800} height={850} />
                </PDFDocument>
              </div> */}
              </div>
            </div>
          </form>
          <BravaViewer />
        </div>
      ) : isCompleted ? (
        <div>
          <form onSubmit={handleSubmit(onMatchingSubmit)}>
            <div className={styles.pageDivider}>
              <div className={styles.documentInfoContainer}>
                <p className={styles.documentName}>{data.filename}</p>
                <table className={styles.table}>
                  <tbody>
                    <tr>
                      <td>
                        <p className={styles.documentInfo}>Filetype: </p>
                      </td>
                      <td style={{ paddingRight: "10px" }} />
                      <td>
                        <p className={styles.tableValue}>{data.extension}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className={styles.documentInfo}>Size: </p>
                      </td>
                      <td />
                      <td>
                        <p className={styles.tableValue}>
                          {data.filesize / 1000} kb
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className={styles.documentInfo}>Channel Type: </p>
                      </td>
                      <td />
                      <td>
                        <p className={styles.tableValue}>{data.channel_type}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className={styles.documentInfo}>Channel: </p>
                      </td>
                      <td />
                      <td>
                        <p className={styles.tableValue}>{data.channel}</p>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <p className={styles.documentInfo}>Document Date: </p>
                      </td>
                      <td />
                      <td>
                        <p className={styles.tableValue}>
                          {data.document_date}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className={styles.documentInfo}>Document Type: </p>
                      </td>
                      <td />
                      <td>
                        <p className={styles.tableValue}>
                          {data.document_type}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className={styles.documentInfo}>
                          Document Template:{" "}
                        </p>
                      </td>
                      <td />
                      <td>
                        <p className={styles.tableValue}>
                          {data.document_template}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div>
                  {getRequiredAttributes.map((reqAttr) => (
                    // <div
                    //   className={styles.requiredAttributeInfo}
                    //   key={reqAttr.field}
                    // >
                    //   <p className={styles.requiredAttributeField}>
                    //     {reqAttr.field}
                    //   </p>
                    //   <p className={styles.tableValue}>{reqAttr.value}</p>
                    //   <TextField
                    //     className={styles.requiredAtrributeValue}
                    //     id="outlined-read-only-input"
                    //     label="Read Only"
                    //     defaultValue={reqAttr.value}
                    //     fullWidth
                    //     size="small"
                    //     InputProps={{
                    //       readOnly: true,
                    //     }}
                    //   />
                    // </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 20,
                      }}
                    >
                      <p className={styles.documentInfo} style={{ flex: 1 }}>
                        {reqAttr.field}:
                      </p>
                      <p className={styles.tableValue} style={{ flex: 2 }}>
                        {reqAttr.value}
                      </p>
                    </div>
                  ))}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 20,
                  }}
                >
                  <p className={styles.documentInfo} style={{ flex: 1 }}>
                    Record:
                  </p>
                  <p className={styles.tableValue} style={{ flex: 2 }}>
                    {data.object}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 20,
                  }}
                >
                  <p className={styles.documentInfo} style={{ flex: 1 }}>
                    Record Type:
                  </p>
                  <p className={styles.tableValue} style={{ flex: 2 }}>
                    {data.object_type}
                  </p>
                </div>

                {invoiceSelectionList.map((invObj) => (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p className={styles.documentInfo} style={{ flex: 1 }}>
                        Invoice ID:
                      </p>
                      <p className={styles.tableValue} style={{ flex: 2 }}>
                        {invObj.id}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p className={styles.documentInfo} style={{ flex: 1 }}>
                        Invoice Status:
                      </p>
                      <p className={styles.tableValue} style={{ flex: 2 }}>
                        {invObj.status}
                      </p>
                    </div>
                  </div>
                ))}

                <div className={styles.buttonsGroup}>
                  <div>
                    <Button
                      variant="outlined"
                      sx={{
                        color: "#83B81B",
                        borderColor: "#83B81B",
                        minWidth: "150px",
                      }}
                      onClick={() => navigate("/documents")}
                    >
                      CLOSE
                    </Button>
                  </div>
                </div>
              </div>

              <div>
                {/* <div className={styles.pdfContainer}>
                <PDFDocument
                  file={pdfData}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page pageNumber={pageNumber} width={800} height={850} />
                </PDFDocument>
              </div> */}
              </div>
            </div>
          </form>
          <BravaViewer />
        </div>
      ) : (
        <div className="documents">
          <CircularProgress style={{ color: "gray" }} size={70} />
        </div>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClick={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setOpen(true);
          }
        }}
      >
        {!isProcessing ? (
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Processing your document
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2, mb: 8 }}>
              The document is being processed, please wait...{" "}
            </Typography>
            <ProgressBar
              animated
              striped
              now={100}
              style={{ ".progress-bar": { backgroundColor: "yellow" } }}
            />
          </Box>
        ) : (
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Processing your document
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2, mb: 8 }}>
              The document has been processed!
            </Typography>
            <ProgressBar animated striped variant="success" now={100} />
          </Box>
        )}
      </Modal>
    </>
  );
};

export default Document;
