import { combineEpics, ofType } from "redux-observable"
import { map } from "rxjs/operators"

// Actions
const TEST_REDUCER = "app/TEST_REDUCER"
const TEST_EPIC = "app/TEST_EPIC"
const SET_BRAVA_APINAME = "app/SET_BRAVA_APINAME"


// Action creators
export const testReducer = (value) => ({ type: TEST_REDUCER, value })
export const testEpic = (value) => ({ type: TEST_EPIC, value })
export const setBravaApiName = (name) => ({ type: SET_BRAVA_APINAME, name })

// Reducer
const reducer = (state = {}, action) => {
  switch (action.type) {
    case TEST_REDUCER: {
      return { ...state, testValue: action.value }
    }
    case SET_BRAVA_APINAME: {
        return { ...state, apiName: action.name };
      }
    default:
      return state
  }
}

// Epics
export const epics = combineEpics((action$, state$) =>
  action$.pipe(
    ofType(TEST_EPIC),
    map((action) => testReducer(action.value))
  )
)

export default reducer