window.publication = {
    // id: "aae95e65-d1a6-4f2c-9749-ef3c83ccb6f8",
    // created: 1674735563683,
    // publishRequest: {},
    // status: "Pending",
    // tags: [],
    // application: {
    //   namespace: "opentext.publishing",
    //   name: "Publications",
    //   version: "1.0.0",
    // },
    // policy: {
    //   namespace: "opentext.publishing.brava",
    //   name: "SimpleBravaView",
    //   version: "1.x",
    // },
    // featureSettings: [
    //   {
    //     feature: {
    //       namespace: "opentext.publishing.sources",
    //       name: "LoadSources",
    //     },
    //     path: "/documents",
    //     value: [
    //       {
    //         url: "https://css.na-1-dev.api.opentext.com/v2/content/cj01MDdjZDUzNi1jOGJhLTQxNTgtYjI1MS04Y2FjYzYxMzBjYzAmaT0yODc5N2ZjMi0yM2YzLTQ1N2QtYWE0Zi0yM2Q1YjQ4MWE5Zjc=/download",
    //         formatHint: "pdf",
    //         filenameHint: "AdminGuide",
    //       },
    //     ],
    //   },
    //   {
    //     feature: {
    //       namespace: "opentext.publishing.execution",
    //       name: "SetPublishingTarget",
    //     },
    //     path: "/publishingTarget",
    //     value:
    //       "https://css.na-1-dev.api.opentext.com/v2/content/cj01MDdjZDUzNi1jOGJhLTQxNTgtYjI1MS04Y2FjYzYxMzBjYzAmaT0yODc5N2ZjMi0yM2YzLTQ1N2QtYWE0Zi0yM2Q1YjQ4MWE5Zjc=/renditions",
    //   },
    // ],
    // errors: [],
    // warnings: [],
    // _embedded: {
    //   "pa:get_publication_artifacts": [],
    //   "otc:get_configs_id": {
    //     id: "a9ae6cfa-531a-4e4f-8d7e-88c48ed01e6d",
    //     policy: {
    //       namespace: "opentext.publishing.brava",
    //       name: "SimpleBravaView",
    //       version: "1.0.1",
    //     },
    //     created: 1674735563811,
    //     features: {
    //       "opentext.publishing.sources@LoadSources@1.0.0": {
    //         documents: [
    //           {
    //             url: "https://css.na-1-dev.api.opentext.com/v2/content/cj01MDdjZDUzNi1jOGJhLTQxNTgtYjI1MS04Y2FjYzYxMzBjYzAmaT0yODc5N2ZjMi0yM2YzLTQ1N2QtYWE0Zi0yM2Q1YjQ4MWE5Zjc=/download",
    //             formatHint: "pdf",
    //             filenameHint: "AdminGuide",
    //           },
    //         ],
    //       },
    //       "opentext.publishing.reports@ReportTextIndices@1.0.0": {
    //         reportName: "search",
    //       },
    //       "opentext.publishing.renditions@ExportThumbnails@1.0.0": {
    //         thumbnailSizes: [
    //           {
    //             width: 0,
    //             height: 200,
    //           },
    //         ],
    //         format: "smallest",
    //       },
    //       "opentext.publishing.renditions@ExportSvg@1.0.0": {
    //         fullColor: {
    //           filename: "vector",
    //         },
    //       },
    //       "opentext.publishing.execution@SetPublishingTarget@1.0.0": {
    //         publishingTarget:
    //           "https://css.na-1-dev.api.opentext.com/v2/content/cj01MDdjZDUzNi1jOGJhLTQxNTgtYjI1MS04Y2FjYzYxMzBjYzAmaT0yODc5N2ZjMi0yM2YzLTQ1N2QtYWE0Zi0yM2Q1YjQ4MWE5Zjc=/renditions",
    //       },
    //     },
    //     application: {
    //       namespace: "opentext.publishing",
    //       name: "Publications",
    //       version: "1.0.0",
    //     },
    //   },
    // },
    // _links: {
    //   self: {
    //     href: "https://na-1-dev.api.opentext.com/publication/api/v1/publications/aae95e65-d1a6-4f2c-9749-ef3c83ccb6f8",
    //   },
    //   curies: [
    //     {
    //       name: "p",
    //       href: "undefined/Publications/{rel}",
    //       templated: true,
    //     },
    //     {
    //       name: "gp",
    //       href: "undefined/Publications/get_publications",
    //     },
    //     {
    //       name: "pa",
    //       href: "undefined/Publications/get_publication_artifacts",
    //     },
    //     {
    //       name: "ac",
    //       href: "undefined/Publications/get_artifact_content",
    //     },
    //     {
    //       name: "otc",
    //       href: "undefined/Configs/{rel}",
    //       templated: true,
    //     },
    //   ],
    //   "gp:get_publication_requiring_source": {
    //     href: "https://na-1-dev.api.opentext.com/publication/api/v1/publications/aae95e65-d1a6-4f2c-9749-ef3c83ccb6f8?withSource={sourceUrl}",
    //     templated: true,
    //   },
    //   "gp:get_publication_embed_all_pages": {
    //     href: "https://na-1-dev.api.opentext.com/publication/api/v1/publications/aae95e65-d1a6-4f2c-9749-ef3c83ccb6f8?embed=page_links",
    //   },
    //   "gp:get_publication_embed_page_range": {
    //     href: "https://na-1-dev.api.opentext.com/publication/api/v1/publications/aae95e65-d1a6-4f2c-9749-ef3c83ccb6f8?embed=page_links&startPage={pageOffset}&maxPages={pageCount}",
    //     templated: true,
    //   },
    //   "pa:get_publication_artifacts": {
    //     href: "https://na-1-dev.api.opentext.com/publication/api/v1/publications/aae95e65-d1a6-4f2c-9749-ef3c83ccb6f8/artifacts",
    //   },
    //   "pa:get_publication_artifacts_embed_all_pages": {
    //     href: "https://na-1-dev.api.opentext.com/publication/api/v1/publications/aae95e65-d1a6-4f2c-9749-ef3c83ccb6f8/artifacts?embed=page_links",
    //   },
    //   "pa:get_publication_artifacts_embed_page_range": {
    //     href: "https://na-1-dev.api.opentext.com/publication/api/v1/publications/aae95e65-d1a6-4f2c-9749-ef3c83ccb6f8/artifacts?embed=page_links&startPage={pageOffset}&maxPages={pageCount}",
    //     templated: true,
    //   },
    //   "pa:get_publication_artifact": {
    //     href: "https://na-1-dev.api.opentext.com/publication/api/v1/publications/aae95e65-d1a6-4f2c-9749-ef3c83ccb6f8/artifacts/{artifactName}{artifactPath}",
    //     templated: true,
    //   },
    //   "ac:get_artifact_content": {
    //     href: "https://na-1-dev.api.opentext.com/publication/api/v1/publications/aae95e65-d1a6-4f2c-9749-ef3c83ccb6f8/artifacts/content/{artifactName}{artifactPath}",
    //     templated: true,
    //   },
    //   "ac:get_artifact_content_embed_all_pages": {
    //     href: "https://na-1-dev.api.opentext.com/publication/api/v1/publications/aae95e65-d1a6-4f2c-9749-ef3c83ccb6f8/artifacts/content/{artifactName}{artifactPath}?embed=page_links",
    //     templated: true,
    //   },
    //   "ac:get_artifact_content_embed_page_range": {
    //     href: "https://na-1-dev.api.opentext.com/publication/api/v1/publications/aae95e65-d1a6-4f2c-9749-ef3c83ccb6f8/artifacts/content/{artifactName}{artifactPath}?embed=page_links&startPage={pageOffset}&maxPages={pageCount}",
    //     templated: true,
    //   },
    //   "p:delete_publication": {
    //     href: "https://na-1-dev.api.opentext.com/publication/api/v1/publications/aae95e65-d1a6-4f2c-9749-ef3c83ccb6f8",
    //   },
    // },
  };
  