import React, { useCallback, useMemo, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { useState, useEffect } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { createUseStyles } from "react-jss";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CircularProgress from "@mui/material/CircularProgress";
import UserContext from "../UserContext";
import { useContext } from "react";
import axios from "axios";
import { DocumentStatus } from "./CustomizeChip";

const useStyles = createUseStyles({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 15px",
  },

  pageTitle: {
    padding: "10px 0px 10px 3px",
  },

  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flex: 0.22,
  },

  headerButton: {
    padding: "0px 20px 0px 20px",
  },
  headerButtonLast: {
    paddingRight: "20px",
    paddingLeft: "10px",
  },
  documentsTableContainer: {
    height: "40vh !important",
  },
  pageSubTitle: {
    padding: "0px 0px 10px 20px",
    fontSize: "28px",
    // color: "#B3B2B3",
  },

  errorHandlerContainer: {
    height: "0vh !important",
    alignItems: "center",
    marginTop: "15px",
  },
});

const OrderDocumentsComponent = ({ orderId }) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const isLoading = useContext(UserContext);
  const [userToken, setUserToken] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  // const [isError, setIsError] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);
  //
  const [style, setStyle] = useState({
    height: "100%",
    width: "100%",
  });
  const cellClickedListener = useCallback((e) => {
    console.log("cellClicked", e);
  });
  const gridRef = useRef();

  const [columnDefs, setColumnDefs] = useState([
    { field: "filename" },
    { field: "document_type", headerName: "Document Type" },
    { field: "object_type", headerName: "Record Type" },
    { field: "object", headerName: "Record" },
    { field: "status", cellRenderer: DocumentStatus },
  ]);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
    }),
    []
  );

  useEffect(() => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
    }
  }, [rowData]);

  var requestOptions = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  };

  useEffect(() => {
    if (!user.isLoading) {
      setUserToken(user.user.signInUserSession.accessToken.jwtToken);
    }
  }, [user.isLoading]);

  async function fetchOrderDocumentData() {
    const orderDocumentDataRequest = await axios.get(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/item/order/${orderId}/documents`,
      requestOptions
    );

    setRowData(orderDocumentDataRequest.data);
  }

  const onGridReady = (params) => {
    setGridApi(params.api);
    fetchOrderDocumentData();
  };

  const deselect = useCallback((e) => {
    gridRef.current.api.deselectAll();
  });

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();

    const id = selectedRows.length === 1 ? selectedRows[0].id : "";

    navigate(`/documents/${id}`);
  }, []);

  // console.log(Object.keys(rowData));
  // console.log(isError);

  console.log(rowData.length);
  // console.log(isError);

  // if (rowData.length === "undefined") {
  //   console.log("TRUE");
  // }

  // if (isLoading) {
  //   return (
  //     // <div className="invoices">
  //     //   <CircularProgress style={{ color: "gray" }} size={70} />
  //     // </div>
  //     <div className={styles.errorHandlerContainer}>
  //       <Alert severity="error">
  //         <AlertTitle>Error</AlertTitle>
  //         <strong>Documents table is unavailable at the moment</strong> - Please
  //         be patient while we work on this issue. Thank you!
  //       </Alert>
  //     </div>
  //   );
  // }
  return (
    <>
      <div style={{ width: "100%", marginTop: 10 }}>
        <div className="ag-theme-alpine">
          <div className={styles.documentsTableContainer}>
            <AgGridReact
              ref={gridRef}
              onCellClicked={cellClickedListener}
              onRowDoubleClicked={onSelectionChanged}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows={true}
              rowSelection="multiple"
              onGridReady={onGridReady}
              enableRangeSelection={true}
              pagination={true}
              paginationPageSize={50}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDocumentsComponent;
