const httpRequire = (url, callback, token) => {
    var xmlhttp = new XMLHttpRequest()
    xmlhttp.open("GET", url, false)
    xmlhttp.setRequestHeader("authorization", "Bearer " + token)
    xmlhttp.send()
  
    if (xmlhttp.status === 200) {
      var m = document.createElement("script")
      m.appendChild(document.createTextNode(xmlhttp.responseText))
      document.getElementsByTagName("head")[0].appendChild(m)
      callback()
      return true
    }
    callback()
    return false
  }
  
  export default httpRequire